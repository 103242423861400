import React, { FC } from 'react';
import { Input, Space, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import clone from 'lodash/clone';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import ProTable from '@src/packages/pro-table/Table';
import { helper } from '@src/controls/controlHelper';
import type { RequestData } from '@src/packages/pro-table';
import * as selectHelper from '@src/controls/selectHelpers';
import { TablePaginationConfig } from 'antd/es/table';
import { SorterResult, TableCurrentDataSource } from 'antd/es/table/interface';

const ArrayTable: FC<{
  schema: {
    name?: string;
    field?: string;
    disabled?: boolean;
    required?: boolean;
    modeSelectField?: string;
    pageId: number;
    type?: string;
    widget?: string;
    imageWidth?: number;
    imageHeight?: number;
    items?: Array<Record<string, any>>;
    intro?: any;
    api: string;
    modelSelectMultiple?: boolean;
    modelSelectField?: string;
    hideExpression?: any;
    default?: any;
    embed?: any;
    flex?: string;
    maxWidth?: string;
    roles?: any[];
    fieldCondition?: any[];
    restProps?: Record<string, any>;
    numberMaskType?: 'digit' | 'percent' | 'money' | 'process';
    rowSelection?: {
      checkStrictly?: boolean;
      hideSelectAll?: boolean;
      isTree?: boolean;
      treeSelectLevel?: number;
      title?: string;
    };
  };
  itemsPerPage?: number;
  selectedRows?: Array<any>;
  pageInfo: any;
  disabled?: boolean;
  invalid?: boolean;
  embeds?: any[];
  type?: 'radio' | 'checkbox';
  value: any;
  onChange?: (keys: any, rows?: any) => void;
}> = (props) => {
  const {
    pageInfo = {},
    schema: { rowSelection },
  } = props;

  const [loading, setLoading] = React.useState(true);
  // const [search, setSearch] = React.useState("")
  const [embeds, setEmbeds] = React.useState<any>(props.embeds || []);
  const [schema, setSchema] = React.useState(props.schema);
  const [columns, setColumns] = React.useState([]);
  const [output, setOutput] = React.useState(clone(props.value || []));
  const [selectedRows, setSelectedRows] = React.useState(
    props.selectedRows || []
  );
  const [mode] = React.useState('select');
  const [pagination, setPagination] = React.useState({
    pageSize: props.itemsPerPage || 10,
    total: 0,
    totalPages: 0,
    current: 1,
  });
  // const [itemsPerPage, setItemsPerPage] = React.useState(props.itemsPerPage || 10)

  const fetchData = async (
    params: any,
    sorter: {
      [key: string]: 'ascend' | 'descend';
    },
    filtered: { [key: string]: React.ReactText[] }
  ) => {
    try {
      let filter: Record<string, any> = {};
      let sort: Array<any> = [];
      filter = Object.keys(filtered).reduce((obj, key) => {
        const newObj: any = { ...obj };
        if (filtered[key] !== null) {
          if (key === 'id') {
            newObj[key] = helper.getValue(filtered[key]);
          } else {
            let v: any = helper.getValue(filtered[key]);
            if (typeof v === 'string') {
              v = { contains: `${v}` };
            }
            newObj[key] = v;
          }
        }
        return newObj;
      }, {});
      if (sorter) {
        sort = Object.keys(sorter).map((key) => {
          return { [key]: sorter[key] === 'descend' ? 'desc' : 'asc' };
        });
      }
      if (sort.length === 0) sort = [{ id: 'desc' }];
      if (mode === 'view') {
        if (filter.id) {
          if (includes(output, filter.id)) {
            filter.id = 0;
          }
        } else {
          filter.id = output;
        }
      }
      if (embeds && embeds.length) {
        embeds.forEach((_embed: any) => {
          const _keyEmbed = Object.keys(_embed)[0];
          filter[_keyEmbed] = _embed[_keyEmbed];
        });
      }
      const nameFieldSelectObj = helper.transformModelSelectField(
        schema.modelSelectField || ''
      );
      const rs: any = await helper.callPageApi(pageInfo, schema?.api, {
        select: Object.keys(nameFieldSelectObj).join(',').toString(),
        sort,
        queryInput: JSON.stringify(filter),
        limit: params.pageSize,
        skip: params.pageSize * (params.current - 1),
      });
      const data = calculateCheck(rs?.data?.data ?? [], output);
      const total = rs?.data.total || rs?.data.count || 0;
      setLoading(false);
      setPagination({
        pageSize: params.pageSize,
        total,
        totalPages:
          rs?.data.totalPages ||
          Math.floor((total + params.pageSize - 1) / params.pageSize),
        current: params.current,
      });

      return {
        data,
        success: true,
        total,
      } as RequestData<any>;
    } catch (error) {
      console.log(`🚀 ~ file: ArrayTable.tsx ~ error`, error);
      return {
        data: [],
        success: true,
        total: 0,
      } as RequestData<any>;
    }
  };

  const calculateCheck = (data: any, output: any) => {
    data.map((d: any) => {
      if (includes(output, d.id)) return (d.checked = true);
      return (d.checked = false);
    });
    return data;
  };

  const onSelectChanged = (
    _record: Record<string, any>,
    _selected: boolean,
    _selectedRows: Array<Record<string, any>>,
    _nativeEvent: any
  ) => {
    const type = props.type || 'checkbox';
    if (type === 'checkbox') {
      let _outputs = clone(output);
      if (!_outputs) {
        _outputs = [];
      }
      let _rows = clone(selectedRows);
      if (_selected) {
        if (!output.some((x: any) => x === _record.id)) {
          _outputs.push(_record.id);
          _rows.push(_record);
        }
      } else {
        _outputs = _outputs.filter((o: any) => o !== _record.id);
        _rows = _rows.filter((o) => o.id !== _record.id);
      }
      setOutput(_outputs);
      setSelectedRows(_rows);
      props.onChange?.(_outputs, _rows);
    } else {
      setOutput([_record.id]);
      setSelectedRows(_selectedRows);
      props.onChange?.([_record.id], _selectedRows);
    }
  };

  const searchInput = React.useRef<any>();
  const [listSearch, setListSearch] = React.useState({});
  const handleSearchFilter = (
    selectedKeys: any[],
    confirm: () => void,
    dataIndex: string
  ) => {
    setListSearch({
      ...listSearch,
      [`search_${dataIndex}`]: selectedKeys[0],
    });
    confirm();
  };

  const handleReset = (clearFilters: any, confirm: any, dataIndex: string) => {
    clearFilters();
    setListSearch({
      ...listSearch,
      [`search_${dataIndex}`]: '',
    });
    confirm();
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: {
      setSelectedKeys: (value: any) => void;
      selectedKeys: any[];
      confirm: () => void;
      clearFilters: () => void;
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Tìm kiếm ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearchFilter(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearchFilter(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, confirm, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    onFilter: (value: any, record: Record<string, any>) => record,
    /* onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ), */
  });

  const calculateColumns = (_schema: any) => {
    return selectHelper.calculateColumns(_schema, getColumnSearchProps);
  };

  const getParams = () => {
    const _params = embeds.reduce((acc: any, cur: any) => {
      const keyCur = Object.keys(cur)[0];
      return {
        ...acc,
        [keyCur]: cur[keyCur],
      };
    }, {});
    return {
      ..._params,
    };
  };

  const onTableChange = (
    changePagination: TablePaginationConfig,
    _filters: {
      [string: string]: any;
    },
    _sorter: SorterResult<any> | SorterResult<any>[],
    _extra: TableCurrentDataSource<any>
  ) => {
    const _pagi = clone(pagination);
    setPagination({
      ..._pagi,
      ...changePagination,
    });
    /* let _sorter: any
    if (sorter && Array.isArray(sorter)) {
      _sorter = sorter.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.field as string]: cur.order,
        }
      }, {})
    } else if(sorter && typeof sorter == 'object' && Object.keys(sorter).length > 0) {
      _sorter = {
        [sorter.field as string]: sorter.order,
      }
    }
    this.fetchData(changePagination, _sorter, _filters) */
  };

  React.useEffect(() => {
    setSchema(props.schema);
    setColumns(calculateColumns(props.schema));
  }, []);

  React.useEffect(() => {
    if (props.schema && props.schema !== schema) {
      setSchema(props.schema);
      setColumns(calculateColumns(props.schema));
    }
  }, [props.schema]);

  React.useEffect(() => {
    if (!isEqual(props.value, output) && props.value) {
      if (Array.isArray(props.value)) {
        setOutput(props.value);
      } else {
        setOutput([props.value]);
      }
    }
  }, [props.value]);

  React.useEffect(() => {
    if (props.itemsPerPage) {
      setPagination((prevPagi) => ({
        ...prevPagi,
        pageSize: props.itemsPerPage || 10,
      }));
    }
  }, [props.itemsPerPage]);

  React.useEffect(() => {
    if (props.selectedRows) setSelectedRows(props.selectedRows);
  }, [props.selectedRows]);

  React.useEffect(() => {
    setEmbeds(props.embeds);
  }, [props.embeds]);

  return (
    <div className='gx-wrapper-select-table'>
      <ProTable
        options={{ setting: false }}
        tableClassName='gx-table-responsive'
        onChange={onTableChange}
        request={fetchData}
        params={getParams}
        search={false}
        headerTitle={rowSelection?.title || 'Danh sách'}
        rowKey='id'
        toolBarRender={false}
        tableAlertRender={false}
        pagination={pagination}
        columns={columns}
        loading={loading}
        rowSelection={{
          type: props.type || 'checkbox',
          selectedRowKeys: output,
          onSelect: onSelectChanged,
        }}
        dateFormatter='string'
        type='table'
      />
    </div>
  );
};

export default ArrayTable;
