import { Component } from 'react';
import {
  Button,
  Modal,
  Upload as AntUpload,
  Progress,
  Typography,
  Space,
} from 'antd';
import {
  UploadOutlined,
  EyeOutlined,
  DeleteOutlined,
  FileTextOutlined,
  DownloadOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  FileWordOutlined,
  FileZipOutlined,
  SoundOutlined,
  FileUnknownOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { fetchFileUrl, upload } from '@src/util/request';
import { helper } from '@src/controls/controlHelper';

const { Text } = Typography;

const StyledUploadWrapper = styled.div`
  .ant-upload-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-bottom: 8px;
  }

  .file-info {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .file-actions {
    display: flex;
    gap: 8px;
  }
`;

const StyledPreviewModal = styled(Modal)`
  .ant-modal-body {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .preview-content {
    margin-top: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    max-height: 500px;
    overflow: auto;
  }

  .preview-actions {
    margin-top: 24px;
  }

  img,
  video {
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
  }

  audio {
    width: 100%;
  }

  iframe {
    width: 100%;
    height: 500px;
    border: none;
  }
`;

interface UploadProps {
  schema: Record<string, any>;
  disabled?: boolean;
  invalid?: boolean;
  value: any;
  onChange?: (val: any) => void;
  allowedTypes?: string[];
}

interface UploadState {
  fileList: any[];
  uploading: boolean;
  modalVisible: boolean;
  previewUrl: string | null;
}

class Upload extends Component<UploadProps, UploadState> {
  constructor(props: UploadProps) {
    super(props);
    this.state = {
      fileList: [],
      uploading: false,
      modalVisible: false,
      previewUrl: null,
    };
  }

  componentDidMount() {
    if (this.props.value) {
      this.fetchFileInfo(this.props.value);
    }
  }

  componentDidUpdate(prevProps: UploadProps) {
    if (this.props.value !== prevProps.value) {
      this.fetchFileInfo(this.props.value);
    }
  }

  async fetchFileInfo(fileId: string) {
    if (!fileId) return;
    try {
      const response = await fetchFileUrl(`/api/file/get-info?id=${fileId}`, {
        method: 'GET',
      });
      this.setState({
        fileList: [
          {
            uid: fileId,
            name: response.fileName,
            status: 'done',
            url: response.url,
          },
        ],
      });
    } catch (err: any) {
      console.error('Error fetching file info:', err);
      helper.alert(err.message);
    }
  }

  handleUpload = async (options: any) => {
    const { onSuccess, onError, file } = options;
    this.setState({ uploading: true });

    try {
      const formData = new FormData();
      formData.append('files', file);
      const rs = await upload(`/api/file/upload-file`, formData);

      onSuccess(rs.created[0]);
      if (this.props.onChange) {
        this.props.onChange(rs.created[0].id);
      }
      this.setState({
        fileList: [
          {
            uid: rs.created[0].id,
            name: rs.created[0].fileName,
            status: 'done',
            url: rs.created[0].url,
          },
        ],
      });
    } catch (err: any) {
      console.error('Error uploading file:', err);
      onError({ err });
      helper.alert(err.message);
    } finally {
      this.setState({ uploading: false });
    }
  };

  handlePreview = async (file: any) => {
    this.setState({
      previewUrl: file.url || file.preview,
      modalVisible: true,
    });
  };

  handleRemove = () => {
    Modal.confirm({
      title: 'Xác nhận xóa',
      content: 'Bạn có chắc chắn muốn xóa file này không?',
      okText: 'Xóa',
      cancelText: 'Hủy',
      onOk: () => {
        this.setState({
          fileList: [],
          previewUrl: null,
          modalVisible: false,
        });
        if (this.props.onChange) {
          this.props.onChange(0);
        }
      },
    });
  };

  getAllowedFileTypes = (): string => {
    const { schema } = this.props;
    const types = schema?.acceptFileTypes;
    if (!types || types.length === 0) return '';

    const mimeTypes: string[] = [];
    types.forEach((type: string) => {
      switch (type) {
        case 'image':
          mimeTypes.push('image/*');
          break;
        case 'audio':
          mimeTypes.push('audio/*');
          break;
        case 'video':
          mimeTypes.push('video/*');
          break;
        case 'pdf':
          mimeTypes.push('application/pdf');
          break;
        case 'excel':
          mimeTypes.push('application/vnd.ms-excel');
          mimeTypes.push(
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          );
          break;
        default:
          mimeTypes.push(type);
      }
    });
    return mimeTypes.join(',');
  };

  getFileType = (url: string, fileName: string): string => {
    // First, try to get the extension from the URL
    let extension = url.split('.').pop()?.toLowerCase();

    // If no extension found in URL, try to get it from the fileName
    if (!extension || extension.includes('?')) {
      extension = fileName.split('.').pop()?.toLowerCase();
    }

    // If still no extension, try to determine type from the URL parameters
    if (!extension) {
      const urlParams = new URLSearchParams(url.split('?')[1]);
      const contentType =
        urlParams.get('content-type') || urlParams.get('contentType');
      if (contentType) {
        extension = contentType.split('/')[1];
      }
    }

    return extension || '';
  };

  getFileIcon = (fileName: string) => {
    const extension = this.getFileType('', fileName);
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return (
          <FileImageOutlined style={{ marginRight: 8, color: '#36cfc9' }} />
        );
      case 'pdf':
        return <FilePdfOutlined style={{ marginRight: 8, color: '#ff4d4f' }} />;
      case 'xls':
      case 'xlsx':
        return (
          <FileExcelOutlined style={{ marginRight: 8, color: '#52c41a' }} />
        );
      case 'doc':
      case 'docx':
        return (
          <FileWordOutlined style={{ marginRight: 8, color: '#1890ff' }} />
        );
      case 'zip':
      case 'rar':
        return <FileZipOutlined style={{ marginRight: 8, color: '#faad14' }} />;
      case 'txt':
        return <FileTextOutlined style={{ marginRight: 8, color: '#999' }} />;
      case 'mp3':
      case 'wav':
      case 'ogg':
      case 'aac':
      case 'm4a':
        return <SoundOutlined style={{ marginRight: 8, color: '#722ed1' }} />;

      default:
        return (
          <FileUnknownOutlined style={{ marginRight: 8, color: '#999' }} />
        );
    }
  };

  renderPreviewContent = () => {
    const { previewUrl } = this.state;
    const file = this.state.fileList[0]; // Assu
    if (!previewUrl) return null;

    const fileType = this.getFileType(previewUrl, file.name);
    switch (fileType) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return (
          <img
            src={previewUrl}
            alt='File preview'
            style={{ maxWidth: '100%' }}
          />
        );
      case 'pdf':
        return (
          <iframe
            src={previewUrl}
            width='100%'
            height='500px'
            title={`PDF Preview: ${file.name}`}
          />
        );
      case 'mp3':
      case 'wav':
      case 'ogg':
        return (
          <audio controls src={previewUrl}>
            Trình duyệt của bạn không hỗ trợ audio
          </audio>
        );
      case 'mp4':
      case 'webm':
        return (
          <video controls width='100%' src={previewUrl}>
            Trình duyệt của bạn không hỗ trợ video
          </video>
        );
      default:
        return (
          <Text>
            Bản xem trước không có sẵn.{' '}
            <a href={previewUrl} target='_blank' rel='noopener noreferrer'>
              Tải file về
            </a>
          </Text>
        );
    }
  };

  render() {
    const { fileList, uploading, modalVisible, previewUrl } = this.state;
    const { disabled } = this.props;
    const acceptFileTypes = this.getAllowedFileTypes();
    const currentFile = fileList[0];

    const uploadProps = {
      accept: acceptFileTypes,
      fileList,
      customRequest: this.handleUpload,
      onChange: ({ fileList }: any) => this.setState({ fileList }),
      onPreview: this.handlePreview,
      onRemove: this.handleRemove,
      disabled,
      showUploadList: false, // Hide the default upload list
    };

    return (
      <StyledUploadWrapper>
        <AntUpload {...uploadProps}>
          <Button
            icon={<UploadOutlined />}
            disabled={disabled || fileList.length > 0}
          >
            Tải file lên
          </Button>
        </AntUpload>
        {fileList.map((file) => (
          <div key={file.uid} className='ant-upload-list-item'>
            <div className='file-info'>
              {this.getFileIcon(file.name)}
              <Text ellipsis style={{ maxWidth: 150 }}>
                {file.name}
              </Text>
            </div>
            <div className='file-actions'>
              <Button
                icon={<EyeOutlined />}
                size='small'
                onClick={() => this.handlePreview(file)}
              />
              <Button
                icon={<DeleteOutlined />}
                size='small'
                onClick={this.handleRemove}
                danger
              />
            </div>
          </div>
        ))}
        {uploading && (
          <Progress percent={99} status='active' style={{ marginTop: 8 }} />
        )}
        <StyledPreviewModal
          title={currentFile ? currentFile.name : 'File Preview'}
          visible={modalVisible}
          onCancel={() => this.setState({ modalVisible: false })}
          footer={null}
          width='80%'
        >
          <div className='preview-content'>{this.renderPreviewContent()}</div>
          <div className='preview-actions'>
            <Space>
              <Button
                icon={<DownloadOutlined />}
                href={previewUrl || ''}
                download={currentFile?.name}
              >
                Tải về
              </Button>
              <Button onClick={() => this.setState({ modalVisible: false })}>
                Đóng
              </Button>
            </Space>
          </div>
        </StyledPreviewModal>
      </StyledUploadWrapper>
    );
  }
}

export default Upload;
