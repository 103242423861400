// import React, { Component } from 'react';
// import { Modal, Button, Tag, Space, Input } from 'antd';
// import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
// import get from 'lodash/get';
// import isEqual from 'lodash/isEqual';
// import ProTable from '@src/packages/pro-table/Table';
// import { helper } from '@src/controls/controlHelper';
// // import Checkbox from './Checkbox'
// import { RequestData } from '@src/packages/pro-table';
// import { COLORS, IS_DEBUG } from '@src/constants/constants';
// import { random } from '@src/util/helpers';

// export interface SingleModelProps {
//   schema: Record<string, any>;
//   disabled?: boolean;
//   invalid?: boolean;
//   value: any;
//   onChange?: (val: any) => void;
//   query: any;
//   embeds?: any[];
// }

// export interface SingleModelState {
//   value: any;
//   modal: boolean;
//   data: Array<any>;
//   loading: boolean;
//   search: string;
//   pageId: string;
//   schema: any;
//   count: number;
//   columns: Array<any>;
//   nPage: number;
//   display: any;
//   mode: 'select' | 'view';
//   pageInfo: any;
//   pagination: {
//     pageSize: number;
//     total: number;
//     totalPages: number;
//     current: number;
//   };
//   embeds: any[];
// }
// class SingleModel extends Component<SingleModelProps, SingleModelState> {
//   constructor(props: any) {
//     console.log(
//       '🚀 ~ file: SingleModel.tsx:47 ~ SingleModel ~ constructor ~ props:',
//       props
//     );
//     super(props);
//     this.state = {
//       value: this.props.value
//         ? Array.isArray(this.props.value)
//           ? this.props.value
//           : [this.props.value]
//         : undefined,
//       modal: false,
//       data: [],
//       loading: true,
//       search: '',
//       pageId: props.schema.pageId,
//       schema: props.schema,
//       count: 0,
//       columns: this.calculateColumns(props.schema),
//       nPage: 0,
//       display: null,
//       mode: 'select',
//       pageInfo: null,
//       pagination: {
//         pageSize: this.itemsPerPage,
//         total: 0,
//         totalPages: 0,
//         current: 1,
//       },
//       embeds: props.embeds,
//     };
//     this.init(props.schema.pageId, props);
//   }
//   itemsPerPage = 10;
//   pageInfo: any = null;
//   schema: any = null;
//   searchInput: any;

//   static getDerivedStateFromProps(
//     nextProps: SingleModelProps,
//     prevState: SingleModelState
//   ) {
//     const propValue = Array.isArray(nextProps.value)
//       ? nextProps.value
//       : [nextProps.value];
//     if (nextProps.value && !isEqual(propValue, prevState.value)) {
//       return {
//         value: propValue,
//       };
//     } else return null; // Triggers no change in the state
//   }

//   componentDidUpdate(prevProps: any, prevState: any) {
//     if (
//       prevState.value !== this.state.value ||
//       this.state.pageInfo !== prevState.pageInfo
//     ) {
//       this.fetchItemName(
//         this.state.pageInfo,
//         this.state.schema,
//         this.state.value,
//         this.state.embeds
//       );
//     }
//   }

//   async init(pageId: number, props: any) {
//     const _pageInfo = await helper.getPage(pageId);
//     this.pageInfo = _pageInfo;
//     this.schema = props.schema;
//     this.setState(
//       {
//         pageInfo: _pageInfo,
//       },
//       () => {
//         this.fetchItemName(_pageInfo, this.schema, props.value, props.embeds);
//       }
//     );
//   }

//   toggle = (mode?: 'select' | 'view') => {
//     if (mode) {
//       this.setState({
//         mode,
//         modal: !this.state.modal,
//       });
//     } else {
//       this.setState({
//         modal: !this.state.modal,
//       });
//     }
//   };

//   fetchData = async (
//     params: any,
//     sorter: {
//       [key: string]: 'ascend' | 'descend';
//     },
//     filtered: { [key: string]: React.ReactText[] }
//   ) => {
//     console.log(filtered);
//     let filter: Record<string, any> = {};
//     let sort: Array<any> = [];
//     filter = Object.keys(filtered).reduce((obj, key) => {
//       const newObj: any = { ...obj };
//       if (filtered[key] !== null) {
//         if (key === 'id') {
//           newObj[key] = Number(helper.getValue(filtered[key]));
//         } else {
//           newObj[key] = { contains: helper.getValue(filtered[key]) };
//         }
//       }
//       return newObj;
//     }, {});
//     if (sorter) {
//       sort = Object.keys(sorter).map((key) => {
//         return { [key]: sorter[key] === 'descend' ? 'desc' : 'asc' };
//       });
//     }
//     if (sort.length === 0) sort = [{ id: 'desc' }];
//     if (this.state.mode === 'view') {
//       if (filter.id) {
//         if (filter.id !== this.props.value) {
//           filter.id = 0;
//         }
//       } else {
//         filter.id = this.props.value;
//       }
//     }
//     const nameFieldSelectObj = helper.transformModelSelectField(
//       this.schema.modelSelectField
//     );
//     console.log('before fetch', filter);
//     const rs: any = await helper.callPageApi(this.pageInfo, this.schema?.api, {
//       select: Object.keys(nameFieldSelectObj).join(',').toString(),
//       sort,
//       queryInput: JSON.stringify(filter),
//       limit: params.pageSize,
//       skip: params.pageSize * (params.current - 1),
//     });
//     const data = this.calculateCheck(
//       rs?.data?.data ?? [],
//       this.schema,
//       this.state.value
//     );
//     this.setState({
//       data,
//       count: rs?.data.count,
//       loading: false,
//       nPage: Math.ceil(rs?.data.count / params.pageSize),
//       pagination: {
//         pageSize: params.pageSize,
//         total: rs?.data.count,
//         totalPages: Math.floor(
//           (get(rs, 'data.count', 0) + params.pageSize - 1) / params.pageSize
//         ),
//         current: params.current,
//       },
//     });
//     return {
//       data,
//       success: true,
//       total: rs?.data.count,
//     } as RequestData<any>;
//   };

//   fetchItemName = async (
//     pageInfo: any,
//     schema: any,
//     value: any,
//     embeds: any
//   ) => {
//     if (!pageInfo || !schema || !value) {
//       this.setState({ display: '' });
//       return;
//     }
//     const filter: Record<string, any> = {};
//     filter.id = Array.isArray(value) ? value[0] : value;
//     if (embeds && embeds.length) {
//       embeds.forEach((_embed: any) => {
//         const _keyEmbed = Object.keys(_embed)[0];
//         filter[_keyEmbed] = _embed[_keyEmbed];
//       });
//     }
//     const rs: any = await helper.callPageApi(pageInfo, schema.api, {
//       queryInput: JSON.stringify(filter),
//       select: 'name',
//     });
//     this.setState({ display: rs?.data?.data[0] ? rs?.data?.data[0].name : '' });
//   };

//   calculateCheck = (data: Array<any>, schema?: any, value?: any) => {
//     data.map((d) => {
//       if (d.id === value?.[0]) return (d.checked = true);
//       return (d.checked = false);
//     });
//     return data;
//   };

//   onChange(e: any) {
//     if (this.props.onChange) {
//       this.props.onChange(e);
//     }
//   }

//   onCheckboxChanged = (keys: Array<any>, _rows: Array<any>) => {
//     if (IS_DEBUG) {
//       console.log(`🚀 ~ file: SingleModel.tsx ~ line 191 ~ keys`, keys);
//     }

//     const data = this.calculateCheck(this.state.data, this.schema, keys);
//     this.setState({ data, value: keys });
//     this.props.onChange?.(keys[0]);
//   };

//   calculateColumns = (schema: any) => {
//     const cols: any = [];
//     const nameFieldSelectObj: any = helper.transformModelSelectField(
//       schema.modelSelectField
//     );
//     Object.keys(nameFieldSelectObj).map((keyField: string) => {
//       cols.push({
//         title: nameFieldSelectObj[keyField],
//         dataIndex: keyField,
//         sorter: true,
//         ...this.getColumnSearchProps(keyField, nameFieldSelectObj[keyField]),
//       });
//       return null;
//     });
//     return cols;
//   };

//   confirm = () => {
//     if (this.props.onChange) {
//       this.props.onChange(this.state.value);
//     }
//     this.toggle();
//   };

//   onRemoveClick(_id: number) {
//     this.setState({ value: undefined, display: '' });
//     this.props.onChange?.(null);
//   }

//   renderNames = () => {
//     return (
//       <Tag
//         // closable
//         color={COLORS[random(11)]}
//         onClose={() => {
//           this.onRemoveClick(this.state.value[0]);
//         }}
//       >
//         {this.state.display || this.state.value || ''}
//       </Tag>
//     );
//   };

//   renderButtonSelect = () => {
//     return null;
//   };

//   handleSearchFilter = (
//     _selectedKeys: (number | string)[],
//     confirm: any,
//     _dataIndex: string
//   ) => {
//     confirm();
//   };
//   handleReset = (clearFilters: any, confirm: any, _dataIndex: string) => {
//     clearFilters();
//     confirm();
//   };
//   getColumnSearchProps = (dataIndex: string, title: string) => ({
//     filterDropdown: ({
//       setSelectedKeys,
//       selectedKeys,
//       confirm,
//       clearFilters,
//     }: any) => (
//       <div style={{ padding: 8 }}>
//         <Input
//           ref={(ref) => (this.searchInput = ref)}
//           placeholder={`Tìm kiếm ${title}`}
//           value={selectedKeys[0]}
//           onChange={(e) =>
//             setSelectedKeys(e.target.value ? [e.target.value] : [])
//           }
//           onPressEnter={() =>
//             this.handleSearchFilter(selectedKeys, confirm, dataIndex)
//           }
//           style={{ width: 188, marginBottom: 8, display: 'block' }}
//         />
//         <Space>
//           <Button
//             type='primary'
//             onClick={() =>
//               this.handleSearchFilter(selectedKeys, confirm, dataIndex)
//             }
//             icon={<SearchOutlined />}
//             size='small'
//             style={{ width: 90 }}
//           >
//             Tìm
//           </Button>
//           <Button
//             onClick={() => this.handleReset(clearFilters, confirm, dataIndex)}
//             size='small'
//             style={{ width: 90 }}
//           >
//             Reset
//           </Button>
//         </Space>
//       </div>
//     ),
//     filterIcon: (filtered: any) => (
//       <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
//     ),
//     onFilterDropdownVisibleChange: (visible: boolean) => {
//       if (visible) {
//         setTimeout(() => {
//           if (this.searchInput && this.searchInput.current) {
//             this.searchInput.current.select();
//           } else if (this.searchInput) {
//             this.searchInput.select();
//           }
//         });
//       }
//     },
//     onFilter: (value: any, record: Record<string, any>) => record,
//   });
//   render() {
//     if (this.schema && !this.schema.modelSelectField)
//       return <p>Thiếu dữ liệu modelSelectField</p>;
//     return (
//       <div className='gx-array-model'>
//         <div className='gx-array-model-display'>
//           {this.renderNames()}
//           <Tag
//             style={{
//               background: '#fff',
//               borderStyle: 'dashed',
//               cursor: 'pointer',
//             }}
//             onClick={() => {
//               if (!this.props.disabled) this.toggle('select');
//             }}
//           >
//             <PlusOutlined />
//             {`Chọn...`}
//           </Tag>
//         </div>

//         <Modal
//           visible={this.state.modal}
//           title='Chọn'
//           onCancel={() => this.toggle()}
//           footer={
//             this.state.mode === 'select' ? null : (
//               <Button type='default' onClick={() => this.toggle()}>
//                 Đóng
//               </Button>
//             )
//           }
//         >
//           <ProTable
//             tableClassName='gx-table-responsive'
//             request={this.fetchData}
//             // params={getParams}
//             search={false}
//             headerTitle={'Danh sách đơn vị'}
//             rowKey='id'
//             toolBarRender={false}
//             tableAlertRender={false}
//             pagination={this.state.pagination}
//             columns={this.state.columns}
//             loading={this.state.loading}
//             rowSelection={{
//               type: 'radio',
//               selectedRowKeys: this.state.value || [],
//               onChange: this.onCheckboxChanged,
//             }}
//             dateFormatter='string'
//             type='table'
//           />
//         </Modal>
//       </div>
//     );
//   }
// }

// export default SingleModel;
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Modal, Button, Tag, Input, Space } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import isEqual from 'lodash/isEqual';
import ProTable from '@src/packages/pro-table/Table';
import { helper } from '@src/controls/controlHelper';
// import Checkbox from './Checkbox'
import { RequestData } from '@src/packages/pro-table';
import { COLORS } from '@src/constants/constants';
import { random } from '@src/util/helpers';
import { clone, cloneDeep } from 'lodash';
import * as selectHelper from '@src/controls/selectHelpers';
import { TablePaginationConfig } from 'antd/es/table';
import { SorterResult, TableCurrentDataSource } from 'antd/es/table/interface';

export interface SingleModelProps {
  schema: {
    name?: string;
    field?: string;
    disabled?: boolean;
    required?: boolean;
    modeSelectField?: string;
    pageId: number;
    type?: string;
    widget?: string;
    imageWidth?: number;
    imageHeight?: number;
    items?: Array<Record<string, any>>;
    intro?: any;
    api: string;
    modelSelectMultiple?: boolean;
    modelSelectField?: string;
    hideExpression?: any;
    default?: any;
    embed?: any;
    flex?: string;
    maxWidth?: string;
    roles?: any[];
    fieldCondition?: any[];
    restProps?: Record<string, any>;
    numberMaskType?: 'digit' | 'percent' | 'money' | 'process';
    rowSelection?: {
      checkStrictly?: boolean;
      hideSelectAll?: boolean;
      isTree?: boolean;
      treeSelectLevel?: number;
      title?: string;
    };
  };
  disabled?: boolean;
  invalid?: boolean;
  value: any;
  onChange?: (val: any) => void;
  formRef?: any;
  embeds?: any[];
}

export interface SingleModelState {
  value: any;
  modal: boolean;
  data: Array<any>;
  loading: boolean;
  search: string;
  pageId: string;
  schema: any;
  count: number;
  columns: Array<any>;
  nPage: number;
  display: any;
  mode: 'select' | 'view';
  pageInfo: any;
  pagination: {
    pageSize: number;
    total: number;
    totalPages: number;
    current: number;
  };
}

const SingleModel: FC<SingleModelProps> = (props: SingleModelProps) => {
  const {
    schema: { rowSelection },
  } = props;
  const itemsPerPage = 10;
  const searchInput = React.useRef<any>();
  const [value, setValue] = React.useState(
    props.value
      ? Array.isArray(props.value)
        ? props.value
        : [props.value]
      : undefined
  );
  const [modal, setModal] = React.useState<boolean>(false);
  const [data, setData] = React.useState<Array<any>>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [embeds, setEmbeds] = React.useState<any>(props.embeds || []);
  const [schema, setSchema] = React.useState<any>(props.schema);
  const [listSearch, setListSearch] = React.useState({});
  const [display, setDisplay] = React.useState<any>(null);
  const [mode, setMode] = React.useState<'select' | 'view'>('select');
  const [pageInfo, setPageInfo] = React.useState<any>(null);
  const [pagination, setPagination] = React.useState<{
    pageSize: number;
    total: number;
    totalPages: number;
    current: number;
  }>({
    pageSize: itemsPerPage,
    total: 0,
    totalPages: 0,
    current: 1,
  });

  const fetchItemName = useCallback(
    async (_pageInfo: any, _schema: any, _value: any) => {
      if (!_pageInfo || !_schema || !_value) {
        setDisplay('');
        return;
      }
      const filter: Record<string, any> = {};
      filter.id = Array.isArray(_value) ? _value[0] : _value;
      const _output = Array.isArray(_value) ? _value : [_value];
      try {
        const nameFieldSelectObj = helper.transformModelSelectField(
          _schema.modelSelectField
        );
        if (embeds && embeds.length) {
          embeds.forEach((_embed: any) => {
            const _keyEmbed = Object.keys(_embed)[0];
            filter[_keyEmbed] = _embed[_keyEmbed];
          });
        }
        const rs: any = await helper.callPageApi(_pageInfo, _schema.api, {
          queryInput: JSON.stringify(filter),
          select: Object.keys(nameFieldSelectObj).join(',').toString(),
        });
        const resData = cloneDeep(rs?.data?.data);
        if (_schema.rowSelection && _schema.rowSelection.isTree) {
          const treeSelectLevel = _schema.rowSelection.treeSelectLevel || 1;
          switch (Number(treeSelectLevel)) {
            case 2: {
              if (resData.length) {
                resData.forEach((c: any) => {
                  if (c.children && c.children.length) {
                    c.children.forEach((c1: any) => {
                      if (_output.includes(c1.id)) {
                        setDisplay(c1.name || '');
                      }
                    });
                  }
                });
              }
              break;
            }
            case 3: {
              if (resData.length) {
                resData.forEach((c: any) => {
                  if (c.children && c.children.length) {
                    c.children.forEach((c1: any) => {
                      if (c1.children && c1.children.length) {
                        c1.children.forEach((c2: any) => {
                          if (_output.includes(c2.id)) {
                            setDisplay(c2.name || '');
                          }
                        });
                      }
                    });
                  }
                });
              }
              break;
            }
            default: {
              setDisplay(rs?.data?.data[0] ? rs?.data?.data[0].name : '');
              break;
            }
          }
        } else {
          setDisplay(resData[0] ? resData[0].name : '');
        }
      } catch (err) {
        console.error(`🚀 ~ SingleModel.tsx ~ fetchItemName ~ err`, err);
      }
    },
    [embeds]
  );

  const init = async (_pageId: number, _props: any) => {
    const _pageInfo = await helper.getPage(_pageId);
    // const _schema = _props.schema
    // this.pageInfo = _pageInfo
    // setSchema(_schema)
    setPageInfo(_pageInfo);
    // await fetchItemName(_pageInfo, _schema, _props.value)
  };

  const toggle = (_mode?: 'select' | 'view') => {
    if (_mode) {
      setMode(_mode);
      setModal(!modal);
    } else {
      setModal(!modal);
    }
  };

  const fetchData = async (
    params: any,
    sorter: {
      [key: string]: 'ascend' | 'descend';
    },
    filtered: { [key: string]: React.ReactText[] }
  ) => {
    try {
      let filter: Record<string, any> = {};
      let sort: Array<any> = [];
      filter = Object.keys(filtered).reduce((obj, key) => {
        const newObj: any = { ...obj };
        if (filtered[key] !== null) {
          if (key === 'id') {
            newObj[key] = Number(helper.getValue(filtered[key]));
          } else {
            let v: any = helper.getValue(filtered[key]);
            if (typeof v === 'string') {
              v = { contains: `${v}` };
            }
            newObj[key] = v;
          }
        }
        return newObj;
      }, {});
      if (sorter) {
        sort = Object.keys(sorter).map((key) => {
          return { [key]: sorter[key] === 'descend' ? 'desc' : 'asc' };
        });
      }
      if (sort.length === 0) sort = [{ id: 'desc' }];
      if (mode === 'view') {
        if (filter.id) {
          if (filter.id !== props.value) {
            filter.id = 0;
          }
        } else {
          filter.id = props.value;
        }
      }
      const nameFieldSelectObj = helper.transformModelSelectField(
        schema.modelSelectField
      );
      if (embeds && embeds.length) {
        embeds.forEach((_embed: any) => {
          const _keyEmbed = Object.keys(_embed)[0];
          filter[_keyEmbed] = _embed[_keyEmbed];
        });
      }
      const rs: any = await helper.callPageApi(pageInfo, schema?.api, {
        select: Object.keys(nameFieldSelectObj).join(',').toString(),
        sort,
        queryInput: JSON.stringify(filter),
        limit: params.pageSize,
        skip: params.pageSize * (params.current - 1),
      });
      const data = calculateCheck(rs?.data?.data ?? [], schema, value);
      const total = rs?.data.total || rs?.data.count || 0;
      setData(data);
      // setCount(total)
      setLoading(false);
      // setNPage(Math.ceil(total / params.pageSize))
      setPagination({
        pageSize: params.pageSize,
        total,
        totalPages:
          rs?.data.totalPages ||
          Math.floor((total + params.pageSize - 1) / params.pageSize),
        current: params.current,
      });
      return {
        data,
        success: true,
        total,
      } as RequestData<any>;
    } catch (error) {
      console.log(`🚀 ~ file: SingleModel.tsx ~ fetchData ~ error`, error);
      return {
        data: [],
        success: true,
        total: 0,
      } as RequestData<any>;
    }
  };

  const calculateCheck = (_data: Array<any>, _schema?: any, _value?: any[]) => {
    _data.map((d) => {
      if (d.id === _value?.[0]) return (d.checked = true);
      return (d.checked = false);
    });
    return _data;
  };

  const onChange = (e: any) => {
    if (props.onChange) {
      props.onChange(e);
    }
  };

  const onCheckboxChanged = (keys: Array<any>, _rows: Array<any>) => {
    const _data = calculateCheck(data, schema, keys);
    setData(_data);
    setValue(keys);
    onChange(keys[0]);
    fetchItemName(pageInfo, schema, keys[0]);
  };

  const handleSearchFilter = (
    selectedKeys: any,
    confirm: () => void,
    dataIndex: string
  ) => {
    setListSearch({
      ...listSearch,
      [`search_${dataIndex}`]: selectedKeys[0],
    });
    confirm();
  };

  const handleReset = (
    clearFilters: any,
    confirm: () => void,
    dataIndex: string
  ) => {
    clearFilters();
    setListSearch({
      ...listSearch,
      [`search_${dataIndex}`]: '',
    });
    confirm();
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: {
      setSelectedKeys: any;
      selectedKeys: any;
      confirm: () => void;
      clearFilters: () => void;
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Tìm kiếm ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearchFilter(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearchFilter(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, confirm, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput?.current?.select());
      }
    },
    onFilter: (value: any, record: any) => record,
  });

  const calculateColumns = (_schema: any) => {
    return selectHelper.calculateColumns(_schema, getColumnSearchProps);
  };

  const [columns, setColumns] = React.useState<Array<any>>(
    calculateColumns(props.schema)
  );

  const confirm = () => {
    onChange(value);
    toggle();
  };

  const onRemoveClick = (_id: number) => {
    setValue(undefined);
    setDisplay('');
    onChange(null);
  };

  const renderNames = () => {
    return (
      <Tag
        key={value && Array.isArray(value) ? (value[0] || {}).id : ''}
        closable={!!props.disabled ? false : value ? true : false}
        color={COLORS[random(11)]}
        onClose={() => {
          onRemoveClick(value && Array.isArray(value) ? value[0] : {});
        }}
      >
        {display || value || ''}
      </Tag>
    );
  };

  const renderButtonSelect = () => {
    return null;
  };

  const getParams = () => {
    const _params = embeds.reduce((acc: any, cur: any) => {
      const keyCur = Object.keys(cur)[0];
      return {
        ...acc,
        [keyCur]: cur[keyCur],
      };
    }, {});
    return {
      ..._params,
    };
  };

  const calculateModalWidth = (columns: any[]) => {
    const minWidth = 520; // Minimum width for the modal
    const maxWidth = window.innerWidth * 0.8; // Maximum width (80% of window width)
    const columnWidth = 200; // Estimated width per column

    const calculatedWidth = columns.length * columnWidth;
    return Math.min(Math.max(calculatedWidth, minWidth), maxWidth);
  };

  const onTableChange = (
    changePagination: TablePaginationConfig,
    _filters: {
      [string: string]: any;
    },
    _sorter: SorterResult<any> | SorterResult<any>[],
    _extra: TableCurrentDataSource<any>
  ) => {
    const _pagi = clone(pagination);
    setPagination({
      ..._pagi,
      ...changePagination,
    });
  };

  React.useEffect(() => {
    init(props.schema.pageId, props.schema);
  }, [props.schema]);

  React.useEffect(() => {
    if (!isEqual(props.value, value)) {
      const _value = props.value
        ? Array.isArray(props.value)
          ? props.value
          : [props.value]
        : undefined;
      setValue(_value);
      // if (pageInfo) fetchItemName(pageInfo, schema, output)
    }
  }, [props.value]);

  React.useEffect(() => {
    if (pageInfo && value) {
      fetchItemName(pageInfo, schema, value);
    }
  }, [pageInfo, value]);

  React.useEffect(() => {
    setSchema(props.schema);
    // setColumns(calculateColumns(props.schema));
  }, [props.schema, calculateColumns]);

  React.useEffect(() => {
    setEmbeds(props.embeds);
  }, [props.embeds]);

  if (schema && !schema.modelSelectField)
    return <p>Thiếu dữ liệu modelSelectField</p>;
  return (
    <div className='gx-array-model'>
      <div className='gx-array-model-display'>
        {renderNames()}
        <Tag
          style={{
            background: '#fff',
            borderStyle: 'dashed',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (!props.disabled) toggle('select');
          }}
        >
          {!!props.disabled ? null : (
            <>
              <PlusOutlined />
              {`Chọn...`}
            </>
          )}
        </Tag>
      </div>
      <Modal
        visible={modal}
        title={rowSelection?.title || 'Chọn'}
        onCancel={() => toggle()}
        destroyOnClose={true}
        width={calculateModalWidth(columns)}
        style={{ top: 20 }}
        bodyStyle={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}
        footer={
          mode === 'select' ? (
            renderButtonSelect()
          ) : (
            <Button type='default' onClick={() => toggle()}>
              Đóng
            </Button>
          )
        }
      >
        <ProTable
          options={{ setting: false }}
          tableClassName='gx-table-responsive'
          onChange={onTableChange}
          request={fetchData}
          params={getParams()}
          search={false}
          headerTitle={'Danh sách đơn vị'}
          rowKey='id'
          toolBarRender={false}
          tableAlertRender={false}
          pagination={pagination}
          columns={columns}
          loading={loading}
          rowSelection={{
            type: 'radio',
            selectedRowKeys: value || [],
            /* renderCell: (checked, record, index, originNode) => {
                return <Tooltip title={'Chọn đơn vị'}>{originNode}</Tooltip>
              }, */
            onChange: onCheckboxChanged,
          }}
          dateFormatter='string'
          type='table'
        />
      </Modal>
    </div>
  );
};

export default SingleModel;
