import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Row, Col, Button, Space, Input, Checkbox } from 'antd';
import React, { useEffect } from 'react';
import {
  ILanguage,
  ILanguageKeyword,
  ISchemaEditorProperties,
} from './SchemaEditor';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useUpdateEffect } from '@src/packages/pro-table/component/util';
type LanguageEditorProps = {
  rowStyles: any;
  labelCols: any;
  inputCols: any;
  schema: ISchemaEditorProperties;
  onPropertyDataChange: any;
};

const LanguageEditor = ({
  rowStyles,
  labelCols,
  inputCols,
  schema,
  onPropertyDataChange,
}: LanguageEditorProps) => {
  const [language, setLanguage] = React.useState<ILanguage[]>(
    schema?.language ?? []
  );
  const [keyword, setKeyword] = React.useState<ILanguageKeyword[]>(
    schema?.keyword ?? []
  );

  useEffect(() => {
    if (schema.language && !_.isEqual(schema.language, language)) {
      setLanguage(schema.language);
    }
    if (schema.keyword && !_.isEqual(schema.keyword, keyword)) {
      setKeyword(schema.keyword);
    }
  }, [keyword, language, schema]);

  useUpdateEffect(() => {
    if (language) onPropertyDataChange('language', language);
  }, [language]);

  useUpdateEffect(() => {
    if (keyword) onPropertyDataChange('keyword', keyword);
  }, [keyword]);

  const addLanguage = () => {
    setLanguage([
      ...language,
      {
        language: '',
        label: '',
        uuid: uuidv4(),
      },
    ]);
  };

  const removeLanguage = (index: number) => {
    const updatedLanguage = [...language];
    updatedLanguage.splice(index, 1);
    setLanguage(updatedLanguage);
  };

  const editLanguage = (index: number, field: string, value: string) => {
    const clone: any = _.cloneDeep(language);
    clone[index][field] = value;
    setLanguage(clone);
  };

  const addKeyword = () => {
    setKeyword([
      ...keyword,
      {
        keyword: '',
        label: '',
        uuid: uuidv4(),
        richtext: false,
      },
    ]);
  };

  const removeKeyword = (index: number) => {
    const updatedKeyword = [...keyword];
    updatedKeyword.splice(index, 1);
    setKeyword(updatedKeyword);
  };

  const editKeyword = (index: number, field: string, value: any) => {
    const clone: any = _.cloneDeep(keyword);
    clone[index][field] = value;
    setKeyword(clone);
  };

  return (
    <Row {...rowStyles} className='gx-mt-2'>
      <Col {...labelCols}>
        <label>Kiểu giao diện</label>
      </Col>
      <Col {...inputCols}>
        <Row gutter={20}>
          <Col span={12}>
            <>
              <Button
                type='dashed'
                onClick={() => {
                  addLanguage();
                }}
                block
                style={{ marginBottom: 10 }}
                icon={<PlusOutlined />}
              >
                Thêm Ngôn Ngữ
              </Button>
              {language.map((item, index) => (
                <Space
                  key={item.uuid}
                  style={{ display: 'flex', height: 46 }}
                  align='baseline'
                >
                  <span>Language: </span>
                  <Input
                    placeholder='vi, en, ...'
                    value={item.language}
                    onChange={(e) => {
                      editLanguage(index, 'language', e.target.value);
                    }}
                  />
                  <Input
                    placeholder='Lable'
                    value={item.label}
                    onChange={(e) => {
                      editLanguage(index, 'label', e.target.value);
                    }}
                  />
                  <MinusCircleOutlined
                    onClick={() => {
                      removeLanguage(index);
                    }}
                  />
                </Space>
              ))}
            </>
          </Col>
          <Col span={12}>
            <>
              <Button
                type='dashed'
                style={{ marginBottom: 10 }}
                onClick={() => addKeyword()}
                block
                icon={<PlusOutlined />}
              >
                Thêm keyword
              </Button>
              {keyword.map((item, index) => (
                <Space
                  key={item.uuid}
                  style={{ display: 'flex', height: 46 }}
                  align='baseline'
                >
                  <span>Keyword: </span>
                  <Input
                    placeholder='name, age, ...'
                    value={item.keyword}
                    onChange={(e) =>
                      editKeyword(index, 'keyword', e.target.value)
                    }
                  />
                  <Input
                    placeholder='tên, tuổi, ...'
                    value={item.label}
                    onChange={(e) =>
                      editKeyword(index, 'label', e.target.value)
                    }
                  />
                  <Checkbox
                    onChange={(e) => {
                      editKeyword(index, 'richtext', e.target.checked);
                    }}
                  >
                    Html
                  </Checkbox>
                  <MinusCircleOutlined
                    onClick={() => {
                      removeKeyword(index);
                    }}
                  />
                </Space>
              ))}
            </>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default LanguageEditor;
