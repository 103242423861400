// export default ArrayModel
// import React, { Component } from 'react'
// import { Modal, Button, Tag, Badge } from 'antd'
// import {
//   CheckOutlined,
//   StopOutlined,
//   CloseOutlined,
//   PlusOutlined,
// } from '@ant-design/icons'
// import get from 'lodash/get'
// import clone from 'lodash/clone'
// import join from 'lodash/join'
// import isEqual from 'lodash/isEqual'
// import includes from 'lodash/includes'
// import ProTable from '@src/packages/pro-table/Table'
// import { helper } from '@src/controls/controlHelper'
// // import Checkbox from './Checkbox'
// import { ActionType, RequestData } from '@src/packages/pro-table'
// import { COLORS } from '@src/constants/constants'
// import { random } from '@src/util/helpers'
// import _ from 'lodash'

// export interface ArrayModelProps {
//   schema: any
//   disabled?: boolean
//   invalid?: boolean
//   value: any
//   onChange?: (val: any) => void
// }

// export interface ArrayModelState {
//   names: Array<any>
//   output: any
//   outputShadow: []
//   value?: any
//   modal: boolean
//   data: Array<any>
//   loading: boolean
//   search: string
//   pageId: string
//   schema: any
//   count: number
//   columns: Array<any>
//   nPage: number
//   display: any
//   mode: 'select' | 'view'
//   pageInfo: any
//   pagination: {
//     pageSize: number
//     total: number
//     totalPages: number
//     current: number
//   }
// }
// class ArrayModel extends Component<ArrayModelProps, ArrayModelState> {
//   form = React.createRef<any>()
//   actionRef = React.createRef<ActionType | undefined>()
//   constructor(props: any) {
//     super(props)
//     this.state = {
//       modal: false,
//       data: [],
//       names: [],
//       loading: true,
//       search: '',
//       pageId: props.schema.pageId,
//       schema: props.schema,
//       count: 0,
//       columns: this.calculateColumns(props.schema),
//       output: clone(props.value || []),
//       outputShadow: clone(props.value || []),
//       nPage: 0,
//       display: null,
//       mode: 'select',
//       pageInfo: null,
//       pagination: {
//         pageSize: this.itemsPerPage,
//         total: 0,
//         totalPages: 0,
//         current: 1,
//       },
//     }
//     this.init(props.schema.pageId, props.schema, clone(props.value || []))
//   }
//   itemsPerPage = 10
//   pageInfo: any = null

//   static getDerivedStateFromProps(
//     nextProps: ArrayModelProps,
//     prevState: ArrayModelState
//   ) {
//     if (nextProps.value) {
//       if (!isEqual(nextProps.value, prevState.output)) {
//         return {
//           output: nextProps.value,
//         }
//       } else return null
//     } else return null // Triggers no change in the state
//   }

//   componentDidMount() {
//     this.setState({
//       outputShadow: this.props.value || [],
//     })
//   }

//   componentDidUpdate(prevProps: any, prevState: any) {
//     if (
//       prevState.output != this.state.output ||
//       this.state.pageInfo != prevState.pageInfo
//     ) {
//       this.fetchItemName(
//         this.state.pageInfo,
//         this.state.schema,
//         this.state.output
//       )
//     }
//   }

//   async init(pageId: number, schema: any, output: any) {
//     const _pageInfo = await helper.getPage(pageId)
//     this.pageInfo = _pageInfo
//     this.setState(
//       {
//         pageInfo: _pageInfo,
//       },
//       () => {
//         this.fetchItemName(_pageInfo, schema, output)
//       }
//     )
//   }

//   start = () => {
//     this.setState({ loading: true })
//     setTimeout(() => {
//       const output: any = []
//       this.setState({
//         output,
//         loading: false,
//       })
//       this.onChange(output)
//       this.fetchItemName(this.pageInfo, this.state.schema, output)
//     }, 1000)
//   }

//   toggle = (e: any, mode?: 'select' | 'view') => {
//     if (mode) {
//       this.setState({
//         mode,
//         modal: !this.state.modal,
//       })
//     } else {
//       this.setState({
//         modal: !this.state.modal,
//       })
//     }
//   }

//   fetchData = async (
//     params: any,
//     sorter: {
//       [key: string]: 'ascend' | 'descend'
//     },
//     filtered: { [key: string]: React.ReactText[] }
//   ) => {
//     try {
//       let filter: Record<string, any> = {}
//       let sort: Array<any> = []
//       const _filtered = Object.keys(filtered).reduce((obj, key) => {
//         const newObj = { ...obj }
//         if (filtered[key] !== null) newObj[key] = helper.getValue(filtered[key])
//         return newObj
//       }, {})
//       const _params = _.omit(params, [
//         'current',
//         'pageSize',
//         'showSizeChanger',
//         'total',
//         'totalPages',
//         'position',
//         '_timestamp',
//       ])
//       filter = { ..._filtered, ..._params }
//       if (sorter) {
//         sort = Object.keys(sorter).map((key) => {
//           return { [key]: sorter[key] == 'descend' ? 'desc' : 'asc' }
//         })
//       }
//       if (sort.length === 0) sort = [{ id: 'desc' }]
//       if (this.state.mode === 'view') {
//         if (filter.id) {
//           if (includes(this.state.output, filter.id)) {
//             filter.id = 0
//           }
//         } else {
//           filter.id = this.state.output
//         }
//       }
//       filter = this.calculateFilter(filter)
//       const nameFieldSelectObj = helper.transformModelSelectField(
//         this.state.schema.modelSelectField
//       )
//       const rs: any = await helper.callPageApi(
//         this.pageInfo,
//         this.state.schema?.api,
//         {
//           select: Object.keys(nameFieldSelectObj).join(',').toString(),
//           sort,
//           queryInput: JSON.stringify(filter),
//           limit: params.pageSize,
//           skip: params.pageSize * (params.current - 1),
//         }
//       )
//       const data = this.calculateCheck(rs?.data?.data ?? [], this.state.output)
//       this.setState({
//         data,
//         count: rs?.data.count,
//         loading: false,
//         nPage: Math.ceil(rs?.data.count / params.pageSize),
//         pagination: {
//           pageSize: params.pageSize,
//           total: rs?.data.count,
//           totalPages: Math.floor(
//             (get(rs, 'data.count', 0) + params.pageSize - 1) / params.pageSize
//           ),
//           current: params.current,
//         },
//       })
//       return {
//         data,
//         success: true,
//         total: rs?.data.count,
//       } as RequestData<any>
//     } catch (error) {
//       console.log(`🚀 ~ file: ArrayModel.tsx ~ line 173 ~ error`, error)
//       return {
//         data: [],
//         success: true,
//         total: 0,
//       } as RequestData<any>
//     }
//   }

//   fetchItemName = async (pageInfo: any, schema: any, output: any) => {
//     if (!pageInfo || !schema || !output) return
//     const filter: Record<string, any> = {}
//     filter.id = output
//     try {
//       const rs: any = await helper.callPageApi(pageInfo, schema.api, {
//         queryInput: JSON.stringify(filter),
//         select: 'name',
//       })
//       const display: Array<any> = []
//       rs?.data?.data.map((d: Record<string, any>) => {
//         return display.push(d.name)
//       })
//       this.setState({ names: rs?.data?.data, display: join(display, '-') })
//     } catch (err) {
//       console.error(
//         `🚀 ~ file: ArrayModel.tsx ~ line 176 ~ fetchItemName ~ err`,
//         err
//       )
//     }
//   }

//   calculateCheck(data: any, output: any) {
//     data.map((d: any) => {
//       if (includes(output, d.id)) return (d.checked = true)
//       return (d.checked = false)
//     })
//     return data
//   }

//   onCheckboxChanged = (keys: Array<any>, _rows: Array<any>) => {
//     const arrs = [...new Set([...this.state.output, ...keys])]
//     const output = [].concat(...arrs)
//     this.setState({ output })
//     this.onChange(output)
//     this.fetchItemName(this.pageInfo, this.state.schema, output)
//   }

//   calculateColumns(schema: any) {
//     const cols: any = []
//     const nameFieldSelectObj = helper.transformModelSelectField(
//       schema.modelSelectField
//     )
//     Object.keys(nameFieldSelectObj).map((keyField: string) => {
//       cols.push({
//         title: nameFieldSelectObj[keyField],
//         dataIndex: keyField,
//         sorter: true,
//       })
//       return null
//     })
//     return cols
//   }

//   calculateFilter(filter: { [key: string]: React.ReactText[] }) {
//     const obj = {}
//     Object.keys(filter).map((f: any) => {
//       const value: any = filter[f]
//       for (let i = 0; i < (this.state.columns || []).length; i++) {
//         const gridInfo = (this.state.columns || [])[i]
//         if (gridInfo.dataIndex === f) {
//           obj[f] = { contains: value }
//         }
//       }
//       return 0
//     })
//     return obj
//   }

//   confirm() {
//     this.onChange(this.state.output)
//     this.fetchItemName(this.pageInfo, this.state.schema, this.state.output)
//     this.toggle(null)
//   }

//   onChange(dt: any) {
//     if (this.props.onChange) {
//       this.props.onChange(dt)
//     }
//   }

//   onRemoveClick(id: number) {
//     const output = [],
//       names = []
//     for (let i = 0; i < this.state.names.length; i++) {
//       if (this.state.names[i].id !== id) {
//         output.push(this.state.names[i].id)
//         names.push(this.state.names[i])
//       }
//     }
//     this.setState({ output, names })
//     this.onChange(output)
//   }

//   renderNames() {
//     if (this.state.names.length === 0) return null
//     if (this.state.names.length <= 4) {
//       return (
//         <React.Fragment>
//           {this.state.names.map((item) => {
//             return item ? (
//               <Tag
//                 key={item.id}
//                 closable
//                 color={COLORS[random(11)]}
//                 onClose={() => {
//                   this.onRemoveClick(item.id)
//                 }}
//               >
//                 {item.name}
//               </Tag>
//             ) : null
//           })}
//         </React.Fragment>
//       )
//     }
//     return null
//   }

//   renderButtonSelect = () => {
//     return null
//     return (
//       <React.Fragment>
//         <Button
//           type="primary"
//           icon={<CheckOutlined />}
//           onClick={() => this.confirm()}
//         >
//           Xác nhận
//         </Button>
//         <Button
//           type="primary"
//           style={{
//             background: '#f8bd1b',
//             borderColor: '#f8bd1b',
//           }}
//           icon={<StopOutlined />}
//           onClick={(e) => {
//             this.toggle(e)
//           }}
//         >
//           Hủy bỏ
//         </Button>
//       </React.Fragment>
//     )
//   }

//   render() {
//     const { loading, output } = this.state
//     const rowSelection = {
//       selectedRowKeys: output,
//       onChange: this.onCheckboxChanged,
//     }
//     const hasSelected = output.length > 0
//     return (
//       <div className="gx-array-model">
//         <div className="gx-array-model-display">
//           {this.renderNames()}
//           <Badge
//             count={this.state.names.length > 0 ? this.state.names.length : 0}
//           >
//             <Tag
//               style={{
//                 background: '#fff',
//                 borderStyle: 'dashed',
//                 cursor: 'pointer',
//               }}
//               onClick={() => {
//                 if (!this.props.disabled) this.toggle('select')
//               }}
//             >
//               <PlusOutlined />
//               {`Chọn...`}
//             </Tag>
//           </Badge>
//         </div>
//         <Modal
//           width={`70vw`}
//           visible={this.state.modal}
//           title="Chọn"
//           onCancel={this.toggle}
//           footer={
//             this.state.mode === 'select' ? (
//               this.renderButtonSelect()
//             ) : (
//               <Button
//                 type="default"
//                 icon={<CloseOutlined />}
//                 onClick={(e) => {
//                   this.onChange(this.state.outputShadow)
//                   this.fetchItemName(
//                     this.pageInfo,
//                     this.state.schema,
//                     this.state.outputShadow
//                   )
//                   this.toggle(e)
//                 }}
//               >
//                 Đóng
//               </Button>
//             )
//           }
//         >
//           <div>
//             <div style={{ marginBottom: 16 }}>
//               <Button
//                 type="dashed"
//                 size="small"
//                 danger
//                 loading={loading}
//                 onClick={this.start}
//                 disabled={!hasSelected}
//               >
//                 Xóa Chọn
//               </Button>
//               <span style={{ marginLeft: 2 }}>
//                 {hasSelected
//                   ? `${output.length} bản ghi được chọn`
//                   : '0 bản ghi được chọn'}
//               </span>
//             </div>
//             <ProTable
//               actionRef={this.actionRef as any}
//               formRef={this.form}
//               tableClassName="gx-table-responsive"
//               request={this.fetchData}
//               // params={getParams}
//               search={true}
//               headerTitle={'Danh sách đơn vị'}
//               rowKey="id"
//               toolBarRender={false}
//               tableAlertRender={false}
//               pagination={this.state.pagination}
//               columns={this.state.columns}
//               loading={this.state.loading}
//               // rowSelection={{
//               //   type: 'checkbox',
//               //   selectedRowKeys: this.state.output,
//               //   /* renderCell: (checked, record, index, originNode) => {
//               //     return <Tooltip title={'Chọn đơn vị'}>{originNode}</Tooltip>
//               //   }, */
//               //   onChange: this.onCheckboxChanged,
//               // }}
//               rowSelection={rowSelection}
//               dateFormatter="string"
//               type="table"
//             />
//           </div>
//         </Modal>
//       </div>
//     )
//   }
// }

// export default ArrayModel
import React, { FC } from 'react';
import { Modal, Button, Tag, Input, Space, Badge } from 'antd';
import { CloseOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import isEqual from 'lodash/isEqual';
import ProTable from '@src/packages/pro-table/Table';
import { helper } from '@src/controls/controlHelper';
// import Checkbox from './Checkbox'
import { RequestData } from '@src/packages/pro-table';
import { COLORS } from '@src/constants/constants';
import { random } from '@src/util/helpers';
import { clone, cloneDeep, includes, omit } from 'lodash';
import * as selectHelper from '@src/controls/selectHelpers';
import { TablePaginationConfig } from 'antd/es/table';
import { SorterResult, TableCurrentDataSource } from 'antd/es/table/interface';

export interface ArrayModelProps {
  schema: {
    name?: string;
    field?: string;
    disabled?: boolean;
    required?: boolean;
    modeSelectField?: string;
    pageId: number;
    type?: string;
    widget?: string;
    imageWidth?: number;
    imageHeight?: number;
    items?: Array<Record<string, any>>;
    intro?: any;
    api: string;
    modelSelectMultiple?: boolean;
    modelSelectField?: string;
    hideExpression?: any;
    default?: any;
    embed?: any;
    flex?: string;
    maxWidth?: string;
    roles?: any[];
    fieldCondition?: any[];
    restProps?: Record<string, any>;
    numberMaskType?: 'digit' | 'percent' | 'money' | 'process';
    rowSelection?: {
      checkStrictly?: boolean;
      hideSelectAll?: boolean;
      isTree?: boolean;
      treeSelectLevel?: number;
      title?: string;
    };
  };
  disabled?: boolean;
  invalid?: boolean;
  value: any;
  onChange?: (val: any) => void;
  formRef?: any;
  embeds?: any[];
  rowSelection?: {
    checkStrictly?: boolean;
    hideSelectAll?: boolean;
    isTree?: boolean;
    treeSelectLevel?: number;
  };
}

export interface ArrayModelState {
  names: Array<any>;
  output: any;
  outputShadow: [];
  value?: any;
  modal: boolean;
  data: Array<any>;
  loading: boolean;
  search: string;
  pageId: string;
  schema: any;
  count: number;
  columns: Array<any>;
  nPage: number;
  display: any;
  mode: 'select' | 'view';
  pageInfo: any;
  pagination: {
    pageSize: number;
    total: number;
    totalPages: number;
    current: number;
  };
}

export interface ArrayModelProps {}

const ArrayModel: FC<ArrayModelProps> = (props: ArrayModelProps) => {
  const {
    schema: { rowSelection },
  } = props;
  const itemsPerPage = 10;
  const [modal, setModal] = React.useState<any>(false);
  const [names, setNames] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<any>(true);
  const [embeds, setEmbeds] = React.useState<any>(props.embeds || []);
  const [schema, setSchema] = React.useState<any>(props.schema);
  const [output, setOutput] = React.useState<any>(clone(props.value || []));
  const [outputShadow, setOutputShadow] = React.useState<any>(
    clone(props.value || [])
  );
  // const [nPage, setNpage] = React.useState<any>(0)
  const [mode, setMode] = React.useState<any>('select');
  const [pageInfo, setPageInfo] = React.useState<any>(null);
  const [pagination, setPagination] = React.useState<any>({
    pageSize: itemsPerPage,
    total: 0,
    totalPages: 0,
    current: 1,
  });

  const init = async (pageId: number, schema: any, output: any) => {
    const _pageInfo = await helper.getPage(pageId);
    setPageInfo(_pageInfo);
    // await fetchItemName(_pageInfo, schema, output)
  };

  const toggle = (e: any, mode?: 'select' | 'view') => {
    if (mode) {
      setMode(mode);
    }
    setModal(!modal);
  };

  const fetchData = async (
    params: any,
    sorter: {
      [key: string]: 'ascend' | 'descend';
    },
    filtered: { [key: string]: React.ReactText[] }
  ) => {
    try {
      let filter: Record<string, any> = {};
      let sort: Array<any> = [];
      filter = Object.keys(filtered).reduce((obj, key) => {
        const newObj: any = { ...obj };
        if (filtered[key] !== null) {
          if (key === 'id') {
            newObj[key] = helper.getValue(filtered[key]);
          } else {
            let v: any = helper.getValue(filtered[key]);
            if (typeof v === 'string') {
              v = { contains: `${v}` };
            }
            newObj[key] = v;
          }
        }
        return newObj;
      }, {});
      if (sorter) {
        sort = Object.keys(sorter).map((key) => {
          return { [key]: sorter[key] === 'descend' ? 'desc' : 'asc' };
        });
      }
      if (sort.length === 0) sort = [{ id: 'desc' }];
      if (mode === 'view') {
        if (filter.id) {
          if (includes(output, filter.id)) {
            filter.id = 0;
          }
        } else {
          filter.id = output;
        }
      }
      const nameFieldSelectObj = helper.transformModelSelectField(
        schema.modelSelectField
      );
      if (embeds && embeds.length) {
        embeds.forEach((_embed: any) => {
          const _keyEmbed = Object.keys(_embed)[0];
          filter[_keyEmbed] = _embed[_keyEmbed];
        });
      }
      const rs: any = await helper.callPageApi(pageInfo, schema?.api, {
        select: Object.keys(nameFieldSelectObj).join(',').toString(),
        sort,
        queryInput: JSON.stringify(filter),
        limit: params.pageSize,
        skip: params.pageSize * (params.current - 1),
      });
      const data = calculateCheck(rs?.data?.data ?? [], output);
      const total = rs?.data.total || rs?.data.count || 0;
      // setData(data)
      // setCount(total)
      setLoading(false);
      // setNpage(Math.ceil(total / params.pageSize))
      setPagination({
        pageSize: params.pageSize,
        total,
        totalPages:
          rs?.data.totalPages ||
          Math.floor((total + params.pageSize - 1) / params.pageSize),
        current: params.current,
      });
      return {
        data,
        success: true,
        total,
      } as RequestData<any>;
    } catch (error) {
      console.log(`🚀 ~ file: ArrayModel.tsx ~ fetchData ~ error`, error);
      return {
        data: [],
        success: true,
        total: 0,
      } as RequestData<any>;
    }
  };

  const fetchItemName = async (_pageInfo: any, _schema: any, _output: any) => {
    if (!_pageInfo || !_schema || !_output) return;
    const filter: Record<string, any> = {};
    if (_output || _output.length > 0) {
      filter.id = _output;
    }
    try {
      const nameFieldSelectObj = helper.transformModelSelectField(
        _schema.modelSelectField
      );
      if (embeds && embeds.length) {
        embeds.forEach((_embed: any) => {
          const _keyEmbed = Object.keys(_embed)[0];
          filter[_keyEmbed] = _embed[_keyEmbed];
        });
      }
      const rs: any = await helper.callPageApi(_pageInfo, _schema.api, {
        queryInput: JSON.stringify(filter),
        select: Object.keys(nameFieldSelectObj).join(',').toString(),
        limit: 9999,
        skip: 0,
      });
      /* const display: Array<any> = []
				rs?.data?.data.forEach((d: Record<string, any>) => {
					return display.push(d.name)
				})
				setDisplay(join(display, "-")) */
      const resData = cloneDeep(rs?.data?.data);
      let data = [];
      if (rowSelection && rowSelection.isTree) {
        const treeSelectLevel = rowSelection.treeSelectLevel || 1;
        switch (Number(treeSelectLevel)) {
          case 1:
          default: {
            data = rs?.data?.data;
            break;
          }
          case 2: {
            if (resData.length) {
              resData.forEach((c: any) => {
                if (c.children && c.children.length) {
                  c.children.forEach((c1: any) => {
                    if (_output.includes(c1.id)) {
                      data.push(c1);
                    }
                  });
                }
              });
            }
            break;
          }
          case 3: {
            if (resData.length) {
              resData.forEach((c: any) => {
                if (c.children && c.children.length) {
                  c.children.forEach((c1: any) => {
                    if (c1.children && c1.children.length) {
                      c1.children.forEach((c2: any) => {
                        if (_output.includes(c2.id)) {
                          data.push(c2);
                        }
                      });
                    }
                  });
                }
              });
            }
            break;
          }
        }
      } else {
        data = rs?.data?.data;
      }
      setNames(data);
    } catch (err) {
      console.error(
        `🚀 ~ file: ArrayModel.tsx ~ line 176 ~ fetchItemName ~ err`,
        err
      );
    }
  };

  const calculateCheck = (_data: any, _output: any) => {
    _data.map((d: any) => {
      if (includes(_output, d.id)) return (d.checked = true);
      return (d.checked = false);
    });
    return _data;
  };

  const onSelectChanged = (
    _record: Record<string, any>,
    _selected: boolean,
    _selectedRows: Array<Record<string, any>>,
    _nativeEvent: any
  ) => {
    let outputs = clone(output);
    if (!outputs) {
      outputs = [];
    }
    if (_selected) {
      if (!outputs.includes(_record.id)) {
        if (rowSelection && rowSelection.isTree) {
          const treeSelectLevel = rowSelection.treeSelectLevel || 1;
          switch (Number(treeSelectLevel)) {
            case 1:
            default: {
              if (!_record.level || _record.level === 1) {
                outputs.push(_record.id);
              }
              break;
            }
            case 2: {
              if (
                (!_record.level || _record.level === 1) &&
                _record.children &&
                _record.children.length
              ) {
                _record.children.forEach((c: any) => {
                  if (!outputs.includes(c.id)) {
                    outputs.push(c.id);
                  }
                });
              } else if (_record.level && _record.level === 2) {
                outputs.push(_record.id);
              }
              break;
            }
            case 3: {
              if (
                (!_record.level || _record.level === 1) &&
                _record.children &&
                _record.children.length
              ) {
                _record.children.forEach((c: any) => {
                  if (c.children && c.children.length) {
                    c.children.forEach((c1: any) => {
                      if (!outputs.includes(c1.id)) {
                        outputs.push(c1.id);
                      }
                    });
                  }
                });
              } else if (
                _record.level &&
                _record.level === 2 &&
                _record.children &&
                _record.children.length
              ) {
                _record.children.forEach((c: any) => {
                  if (!outputs.includes(c.id)) {
                    outputs.push(c.id);
                  }
                });
              } else if (_record.level && _record.level === 3) {
                outputs.push(_record.id);
              }
              break;
            }
          }
        } else {
          outputs.push(_record.id);
        }
      }
    } else {
      if (rowSelection && rowSelection.isTree) {
        const treeSelectLevel = rowSelection.treeSelectLevel || 1;
        switch (Number(treeSelectLevel)) {
          case 1:
          default: {
            if (!_record.level || _record.level === 1) {
              outputs = outputs.filter((o: any) => o !== _record.id);
            }
            break;
          }
          case 2: {
            if (
              (!_record.level || _record.level === 1) &&
              _record.children &&
              _record.children.length
            ) {
              _record.children.forEach((c: any) => {
                outputs = outputs.filter((o: any) => o !== c.id);
              });
            } else if (_record.level && _record.level === 2) {
              outputs = outputs.filter((o: any) => o !== _record.id);
            }
            break;
          }
          case 3: {
            if (
              (!_record.level || _record.level === 1) &&
              _record.children &&
              _record.children.length
            ) {
              _record.children.forEach((c: any) => {
                if (c.children && c.children.length) {
                  c.children.forEach((c1: any) => {
                    outputs = outputs.filter((o: any) => o !== c1.id);
                  });
                }
              });
            } else if (
              _record.level &&
              _record.level === 2 &&
              _record.children &&
              _record.children.length
            ) {
              _record.children.forEach((c: any) => {
                outputs = outputs.filter((o: any) => o !== c.id);
              });
            } else if (_record.level && _record.level === 3) {
              outputs = outputs.filter((o: any) => o !== _record.id);
            }
            break;
          }
        }
      } else {
        outputs = outputs.filter((o: any) => o !== _record.id);
      }
    }
    setOutput(outputs);
    onChange(outputs);
    fetchItemName(pageInfo, schema, outputs);
  };

  const onSelectAll = (
    _selected: boolean,
    _selectedRows: Array<Record<string, any>>,
    _changeRows: any[]
  ) => {
    let outputs = clone(output);
    if (!outputs) {
      outputs = [];
    }
    if (_selected) {
      // tree
      if (rowSelection && rowSelection.isTree) {
        const treeSelectLevel = rowSelection.treeSelectLevel || 1;
        _changeRows.forEach((_record) => {
          if (!outputs.includes(_record.id)) {
            switch (Number(treeSelectLevel)) {
              case 1:
              default: {
                if (!_record.level || _record.level === 1) {
                  outputs.push(_record.id);
                }
                break;
              }
              case 2: {
                if (
                  (!_record.level || _record.level === 1) &&
                  _record.children &&
                  _record.children.length
                ) {
                  _record.children.forEach((c: any) => {
                    if (!outputs.includes(c.id)) {
                      outputs.push(c.id);
                    }
                  });
                } else if (_record.level && _record.level === 2) {
                  outputs.push(_record.id);
                }
                break;
              }
              case 3: {
                if (
                  (!_record.level || _record.level === 1) &&
                  _record.children &&
                  _record.children.length
                ) {
                  _record.children.forEach((c: any) => {
                    if (c.children && c.children.length) {
                      c.children.forEach((c1: any) => {
                        if (!outputs.includes(c1.id)) {
                          outputs.push(c1.id);
                        }
                      });
                    }
                  });
                } else if (
                  _record.level &&
                  _record.level === 2 &&
                  _record.children &&
                  _record.children.length
                ) {
                  _record.children.forEach((c: any) => {
                    if (!outputs.includes(c.id)) {
                      outputs.push(c.id);
                    }
                  });
                } else if (_record.level && _record.level === 3) {
                  outputs.push(_record.id);
                }
                break;
              }
            }
          }
        });
      } else {
        _changeRows.forEach((_record) => {
          if (!outputs.includes(_record.id)) {
            outputs.push(_record.id);
          }
        });
      }
    } else {
      // tree
      if (rowSelection && rowSelection.isTree) {
        const treeSelectLevel = rowSelection.treeSelectLevel || 1;
        _changeRows.forEach((_record) => {
          switch (Number(treeSelectLevel)) {
            case 1:
            default: {
              if (!_record.level || _record.level === 1) {
                outputs = outputs.filter((o: any) => o !== _record.id);
              }
              break;
            }
            case 2: {
              if (
                (!_record.level || _record.level === 1) &&
                _record.children &&
                _record.children.length
              ) {
                _record.children.forEach((c: any) => {
                  outputs = outputs.filter((o: any) => o !== c.id);
                });
              } else if (_record.level && _record.level === 2) {
                outputs = outputs.filter((o: any) => o !== _record.id);
              }
              break;
            }
            case 3: {
              if (
                (!_record.level || _record.level === 1) &&
                _record.children &&
                _record.children.length
              ) {
                _record.children.forEach((c: any) => {
                  if (c.children && c.children.length) {
                    c.children.forEach((c1: any) => {
                      outputs = outputs.filter((o: any) => o !== c1.id);
                    });
                  }
                });
              } else if (
                _record.level &&
                _record.level === 2 &&
                _record.children &&
                _record.children.length
              ) {
                _record.children.forEach((c: any) => {
                  outputs = outputs.filter((o: any) => o !== c.id);
                });
              } else if (_record.level && _record.level === 3) {
                outputs = outputs.filter((o: any) => o !== _record.id);
              }
              break;
            }
          }
        });
      } else {
        _changeRows.forEach((_record) => {
          outputs = outputs.filter((o: any) => o !== _record.id);
        });
      }
    }
    setOutput(outputs);
    onChange(outputs);
    fetchItemName(pageInfo, schema, outputs);
  };

  const calculateColumns = (_schema: any) => {
    return selectHelper.calculateColumns(_schema, getColumnSearchProps);
  };

  const searchInput = React.useRef<any>();
  const [listSearch, setListSearch] = React.useState({});
  const handleSearchFilter = (
    selectedKeys: any[],
    confirm: () => void,
    dataIndex: string
  ) => {
    setListSearch({
      ...listSearch,
      [`search_${dataIndex}`]: selectedKeys[0],
    });
    confirm();
  };

  const handleReset = (clearFilters: any, confirm: any, dataIndex: string) => {
    clearFilters();
    setListSearch({
      ...listSearch,
      [`search_${dataIndex}`]: '',
    });
    confirm();
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: {
      setSelectedKeys: (value: any) => void;
      selectedKeys: any[];
      confirm: () => void;
      clearFilters: () => void;
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Tìm kiếm ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearchFilter(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearchFilter(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Tìm
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, confirm, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    onFilter: (value: any, record: Record<string, any>) => record,
    /* onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ), */
  });

  const [columns, setColumns] = React.useState<any>(
    calculateColumns(props.schema)
  );

  const onChange = (dt: any) => {
    if (props.onChange) {
      props.onChange(dt);
    }
  };

  const onRemoveClick = (id: number) => {
    const _output = [];
    const _names = [];
    for (let i = 0; i < names.length; i++) {
      if (names[i].id !== id) {
        _output.push(names[i].id);
        _names.push(names[i]);
      }
    }
    setOutput(_output);
    setNames(_names);
    onChange(_output);
  };

  const renderNames = () => {
    if (!names || names.length === 0) return null;
    if (names.length <= 4) {
      return (
        <React.Fragment>
          {names.map((item: any) => {
            return item ? (
              <Tag
                key={item.id}
                closable={!!props.disabled ? false : true}
                color={COLORS[random(11)]}
                onClose={() => {
                  onRemoveClick(item.id);
                }}
              >
                {item.name}
              </Tag>
            ) : null;
          })}
        </React.Fragment>
      );
    }
    return null;
  };

  const renderButtonSelect = () => {
    return null;
  };

  const getParams = () => {
    const _params = embeds.reduce((acc: any, cur: any) => {
      const keyCur = Object.keys(cur)[0];
      return {
        ...acc,
        [keyCur]: cur[keyCur],
      };
    }, {});
    return {
      ..._params,
    };
  };

  const onTableChange = (
    changePagination: TablePaginationConfig,
    _filters: {
      [string: string]: any;
    },
    _sorter: SorterResult<any> | SorterResult<any>[],
    _extra: TableCurrentDataSource<any>
  ) => {
    const _pagi = clone(pagination);
    setPagination({
      ..._pagi,
      ...changePagination,
    });
  };

  const calculateModalWidth = (columns: any[]) => {
    const minWidth = 520; // Minimum width for the modal
    const maxWidth = window.innerWidth * 0.8; // Maximum width (80% of window width)
    const columnWidth = 200; // Estimated width per column

    const calculatedWidth = columns.length * columnWidth;
    return Math.min(Math.max(calculatedWidth, minWidth), maxWidth);
  };

  React.useEffect(() => {
    setOutputShadow(props.value || []);
    init(props.schema.pageId, props.schema, clone(props.value || []));
  }, []);

  React.useEffect(() => {
    if (!isEqual(props.value, output) && props.value) {
      if (Array.isArray(props.value)) {
        setOutput(props.value);
      } else {
        setOutput([props.value]);
      }
    }
  }, [props.value]);

  React.useEffect(() => {
    if (pageInfo && output && output.length > 0) {
      fetchItemName(pageInfo, schema, output);
    }
  }, [pageInfo, output]);

  React.useEffect(() => {
    setSchema(props.schema);
    setColumns(calculateColumns(props.schema));
  }, [props.schema]);

  React.useEffect(() => {
    setEmbeds(props.embeds);
  }, [props.embeds]);

  return (
    <div className='gx-array-model'>
      <div className='gx-array-model-display'>
        {renderNames()}
        <Badge count={names && names.length > 0 ? names.length : 0}>
          <Tag
            style={{
              background: '#fff',
              borderStyle: 'dashed',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (!props.disabled) toggle('select');
            }}
          >
            {!!props.disabled ? null : (
              <>
                <PlusOutlined />
                {`Chọn...`}
              </>
            )}
          </Tag>
        </Badge>
      </div>
      <Modal
        width={calculateModalWidth(columns)}
        visible={modal}
        title={rowSelection?.title || 'Chọn'}
        onCancel={toggle}
        destroyOnClose={true}
        footer={
          mode === 'select' ? (
            renderButtonSelect()
          ) : (
            <Button
              type='default'
              icon={<CloseOutlined />}
              onClick={(e) => {
                toggle(e);
                onChange(outputShadow);
                fetchItemName(pageInfo, schema, outputShadow);
              }}
            >
              Đóng
            </Button>
          )
        }
      >
        <ProTable
          options={{ setting: false }}
          tableClassName='gx-table-responsive'
          onChange={onTableChange}
          request={fetchData}
          params={getParams}
          search={false}
          headerTitle={rowSelection?.title || 'Danh sách'}
          rowKey='id'
          toolBarRender={false}
          tableAlertRender={false}
          pagination={pagination}
          columns={columns}
          loading={loading}
          rowSelection={{
            ...omit(rowSelection, ['isTree', 'treeSelectLevel']),
            type: 'checkbox',
            selectedRowKeys: output,
            onSelect: onSelectChanged,
            onSelectAll,
          }}
          dateFormatter='string'
          type='table'
        />
      </Modal>
    </div>
  );
};

export default ArrayModel;
