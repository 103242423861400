import { Component } from 'react';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  // Cell,
  Legend,
  CartesianGrid,
  Rectangle,
  TooltipProps,
} from 'recharts';
import Widget from '@src/components/Widget/index';
import DatePickerComponent from '@src/packages/pro-component/components/DatePicker';
import { Form, Button } from 'antd';
import { helper } from '@src/controls/controlHelper';
import Loader from '@src/components/Loading';
import dayjs from 'dayjs';

export interface ReportByFactoryProps {
  location?: any;
}

export interface ReportByFactoryState {
  error?: any;
  loading?: boolean;
  data: any;
  fromDate: any;
  toDate: any;
}

// const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink'];

// const getPath = (x: any, y: any, width: any, height: any) => {
//   return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${
//     y + height / 3
//   }
//   ${x + width / 2}, ${y}
//   C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
//     x + width
//   }, ${y + height}
//   Z`;
// };

// const TriangleBar = (props: any) => {
//   const { fill, x, y, width, height } = props;
//   return <path d={getPath(x, y, width, height)} stroke='none' fill={fill} />;
// };

const formatVnd = (d: any) => {
  return d.toLocaleString('vi-VN', {
    style: 'currency',
    currency: 'VND',
  });
};

const CustomTooltip: React.FC<TooltipProps<any, string>> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    const item = payload[0].payload;
    return (
      <div
        className='custom-tooltip'
        style={{
          margin: '0px',
          padding: '10px',
          backgroundColor: 'rgb(255, 255, 255)',
          border: '1px solid rgb(204, 204, 204)',
          whiteSpace: 'nowrap',
        }}
      >
        <p
          className='label'
          style={{ color: '#fa8c16', fontWeight: 'bold !important' }}
        >
          {`Thống kê ${label}`}
        </p>
        <p className='intro'>
          <span style={{ color: '#038fde' }}>
            Tổng tiền: {formatVnd(item.customerAmount)}
          </span>
        </p>
        <p className='intro'>
          <span style={{ color: '#038fde' }}>
            Số lượng mua: {item.customerPay}
          </span>
        </p>
      </div>
    );
  }

  return null;
};

export default class ReportByFactory extends Component<
  ReportByFactoryProps,
  ReportByFactoryState
> {
  constructor(props: ReportByFactoryProps) {
    super(props);

    this.state = {
      error: null,
      loading: true,
      data: null,
      fromDate: dayjs().startOf('month').toDate(),
      toDate: dayjs().endOf('day').toDate(),
    };
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    const dataDash = await this.requestData();
    this.setState({
      loading: false,
      data: dataDash,
    });
  }

  async requestData() {
    const dataDash = await helper.callPublicApi(
      'dashboard-pay',
      '/api/admin/dashboard/pay',
      'POST',
      {
        fromDate: this.state.fromDate?.valueOf(),
        toDate: this.state.toDate?.valueOf(),
      }
    );
    return dataDash;
  }

  handleChangeFromDate = async (value: any) => {
    this.setState({ fromDate: value });
  };
  handleChangeToDate = async (value: any) => {
    this.setState({ toDate: value });
  };

  handleSubmit = async () => {
    this.init();
  };

  render() {
    const { data } = this.state;
    if (!data) return <Loader />;

    return (
      <Widget>
        <div className='gx-dealclose-header'>
          <div>
            <h2 className='h2 gx-mb-2'>
              Thống kê lượt mua/doanh thu theo ngày
            </h2>
          </div>
          <div className='gx-dealclose-header-right'>
            <Form
              layout='inline'
              className='gx-form-inline-label-up gx-form-inline-currency'
            >
              <Form.Item label='Từ ngày' className='gx-form-item-three-fourth'>
                <DatePickerComponent
                  showTime={false}
                  value={
                    this.state.fromDate
                      ? dayjs(this.state.fromDate).startOf('day')
                      : dayjs().startOf('day')
                  }
                  onChange={(e) => {
                    this.handleChangeFromDate(e);
                  }}
                  disabled={false}
                />
              </Form.Item>
              <Form.Item label='Đến ngày' className='gx-form-item-three-fourth'>
                <DatePickerComponent
                  showTime={false}
                  value={
                    this.state.toDate
                      ? dayjs(this.state.toDate).endOf('day')
                      : dayjs().endOf('day')
                  }
                  onChange={(e) => {
                    this.handleChangeToDate(e);
                  }}
                  disabled={false}
                />
              </Form.Item>
              <Form.Item className='gx-d-block gx-mb-1'>
                <Button
                  className='gx-mb-0'
                  type='primary'
                  onClick={this.handleSubmit}
                >
                  Áp dụng
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
        {/* <ResponsiveContainer width='100%' height={550}>
          <BarChart
            data={data?.data}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <Tooltip />
            <XAxis
              dataKey='createdAt'
              type='category'
              allowDataOverflow={true}
              padding='no-gap'
            />
            <YAxis />
            <CartesianGrid strokeDasharray='3 3' />
            <Bar
              dataKey='customerPay'
              name='Số lượng mua'
              stackId='a'
              fill='#4BB543'
              barSize={15}
              shape={<TriangleBar />}
              label={{ position: 'top' }}
            >
              {data?.data.map((entry: any, index: any) => (
                <Cell key={`cell-${index}`} fill={colors[index % 20]} />
              ))}
            </Bar>
            <Bar
              dataKey='customerAmount'
              name='Tổng tiền'
              stackId='a'
              fill='#4BB543'
              barSize={15}
              shape={<TriangleBar />}
              label={{ position: 'top' }}
            >
              {data?.data.map((entry: any, index: any) => (
                <Cell key={`cell-${index}`} fill={colors[index % 20]} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer> */}
        <ResponsiveContainer width='100%' height={550}>
          <BarChart
            data={data?.data}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='createdAt' />
            {/* <YAxis
              yAxisId='left'
              tickFormatter={formatVndStick}
              orientation='left'
              stroke='#8884d8'
            />
            <YAxis yAxisId='right' orientation='right' stroke='#8884d8' /> */}
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            {/* <Tooltip /> */}
            <Legend />
            <Bar
              dataKey='customerAmount'
              name='Tổng tiền'
              fill='#82ca9d'
              activeBar={<Rectangle fill='gold' stroke='purple' />}
            />
            <Bar
              dataKey='customerPay'
              name='Số lượng mua'
              fill='#8884d8'
              activeBar={<Rectangle fill='pink' stroke='blue' />}
            />
          </BarChart>
        </ResponsiveContainer>
      </Widget>
    );
  }
}
