import { helper } from './controlHelper';

export const calculateColumns = (schema: any, getColumnSearchProps: any) => {
  const cols: any = [];
  const nameFieldSelectObj: any = helper.transformModelSelectField(
    schema.modelSelectField
  );
  Object.keys(nameFieldSelectObj).map((keyField: string) => {
    let _keyField;
    try {
      _keyField = JSON.parse(keyField);
    } catch (error) {
      _keyField = keyField;
    }
    if (
      new RegExp(
        /^[a-zA-Z\d]*(image|images|avatar|logo|thumbnail)+[a-zA-Z\d]*$/,
        'gi'
      ).test(keyField)
    ) {
      cols.push({
        title: nameFieldSelectObj[keyField],
        dataIndex: _keyField,
        sorter: true,
        valueType: 'avatar',
      });
    } else {
      cols.push({
        title: nameFieldSelectObj[keyField],
        dataIndex: _keyField,
        sorter: true,
        ...getColumnSearchProps(keyField),
      });
    }
    return null;
  });
  return cols;
};
