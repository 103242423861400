import Base from '@src/packages/pro-component/schema/Base';
import { ISchemaSetting } from '@src/routes/default/pageManager/PageEditor';
import React, { FC } from 'react';
import { Row, Col, Divider } from 'antd';
import { helper } from '../controlHelper';
import { ISchemaEditorProperties } from '../editors/SchemaEditor';
import { evaluateExpression } from '@src/util/helpers';

export interface FormOneColumnProps {
  children?: React.ReactNode;
  schema: ISchemaEditorProperties[];
  settings: ISchemaSetting;
  data: { [x: string]: any };
  itemId: string | number | undefined;
  formRef?: any;
}

interface ISchemaEditorPropertiesWithWidget extends ISchemaEditorProperties {
  widget:
    | 'NumberRange'
    | 'NumberMask'
    | 'Explain'
    | 'Time'
    | 'Captcha'
    | 'Upload'
    | 'UploadGetUrl'
    | 'Password'
    | 'ArrayModel'
    | 'ArraySelect'
    | 'SingleSelect'
    | 'ArrayImage'
    | 'Enum'
    | 'EnumByUser'
    | 'RichText'
    | 'TextArea'
    | 'Image'
    | 'Location'
    | 'Text'
    | 'SingleModel'
    | 'Checkbox'
    | 'Date'
    | 'DateTime'
    | 'RadioGroup'
    | 'Slider'
    | 'Icon'
    | 'Json';
}

const FormOneColumn: FC<FormOneColumnProps> = (props: FormOneColumnProps) => {
  const { schema, data, itemId, settings, children, formRef } = props;
  const spanCol = React.useMemo(
    () => 24 / settings.columns,
    [settings.columns]
  );

  const renderDiviver = (index: number) => {
    if (settings.divider === 'none') {
      return null;
    } else if ((index + 1) % Number(settings.columns) === 0) {
      if (
        settings.dividerText === true &&
        settings.dividerTextItems &&
        Array.isArray(settings.dividerTextItems) &&
        settings.dividerTextItems[(index + 1) / Number(settings.columns) - 1] &&
        settings.dividerTextItems[(index + 1) / Number(settings.columns) - 1]
          .show === true
      ) {
        return (
          <Divider orientation={settings.divider}>
            <span className='gx-text-light'>
              {
                settings.dividerTextItems[
                  (index + 1) / Number(settings.columns) - 1
                ].title
              }
            </span>
          </Divider>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  return (
    <>
      <Row gutter={[settings.horizontal, settings.vertical]}>
        {schema.map((comp: ISchemaEditorProperties, index: number) => {
          const flexStyleProps: any = {};
          if (comp.flex && comp.flex !== '') {
            flexStyleProps.flex = comp.flex;
          }
          if (comp.maxWidth && comp.maxWidth !== '') {
            flexStyleProps.style = {
              maxWidth: comp.maxWidth,
            };
          }
          if (comp.hideExpression) {
            let str = comp.hideExpression;
            for (const i in data) {
              str = helper.replaceAll(str, i, data[i]);
            }
            try {
              // if (window.eval(str)) return null;
              if (evaluateExpression(str, comp)) return null;
            } catch (err) {}
          }
          return (
            <React.Fragment key={index}>
              <Col
                {...flexStyleProps}
                key={comp.field}
                xxl={spanCol}
                xl={spanCol}
                lg={spanCol}
                md={spanCol}
                sm={24}
                xs={24}
              >
                <Base
                  // ref={refBase}
                  key={index}
                  schema={comp as ISchemaEditorPropertiesWithWidget}
                  /* onChange={(e) => {
                    const dt = Object.assign({}, this.props.data)
                    dt[comp.field] = e
                    if (this.props.onChange) {
                      this.props.onChange(dt)
                    }
                  }} */
                  // value={this.props.data[comp.field]}
                  data={data}
                  itemId={itemId || null}
                  fields={[]}
                  formRef={formRef}
                />
              </Col>
              {renderDiviver(index)}
            </React.Fragment>
          );
        })}
      </Row>
      <Divider orientation='left'></Divider>
      <Row gutter={[16, 16]} justify='end' align='bottom'>
        <Col
          flex={`0`}
          xxl={{
            span: spanCol,
          }}
          xl={{
            span: spanCol,
          }}
          lg={{
            span: spanCol,
          }}
          md={{
            span: spanCol,
          }}
          sm={24}
          xs={24}
        >
          {children}
        </Col>
      </Row>
    </>
  );
};

export default React.memo(FormOneColumn);
