import { FC, useEffect, useState } from 'react';
import { JsonEditor as Editor } from 'jsoneditor-react';
import ace from 'brace';

import 'brace/mode/json';
import 'brace/theme/solarized_light';

const JSONWidget: FC<{
  value?: any;
  onChange?: (val: any) => void;
}> = (props) => {
  const { value, onChange, ...rest } = props;

  // const parseValue = (val: any): any => {
  //   if (val === undefined || val === null) {
  //     return undefined;
  //   }
  //   if (typeof val === 'string') {
  //     try {
  //       const cleanedString = val.replace(/\\"/g, '"').replace(/^"|"$/g, '');
  //       const parsedValue = JSON.parse(cleanedString);
  //       return JSON.parse(JSON.stringify(parsedValue));
  //     } catch (error) {
  //       console.error('Error parsing JSON:', error);
  //       return val;
  //     }
  //   }
  //   return JSON.parse(JSON.stringify(val));
  // };
  const parseValue = (val: any): any => {
    if (val === undefined || val === null) {
      return undefined;
    }
    if (typeof val === 'string') {
      try {
        // First, try to parse it directly
        return JSON.parse(val);
      } catch (error) {
        // If direct parsing fails, try to clean the string
        try {
          // Remove outer quotes if present
          const trimmed = val.replace(/^"(.*)"$/, '$1');
          // Replace escaped quotes with temporary placeholders
          const unescaped = trimmed.replace(/\\"/g, '__QUOTE__');
          // Replace double backslashes with single backslashes
          const singleBackslash = unescaped.replace(/\\\\/g, '\\');
          // Now parse the cleaned string
          const parsed = JSON.parse(singleBackslash);
          // Recursively replace the placeholders back with quotes
          const restore = (obj: any): any => {
            if (typeof obj === 'string') {
              return obj.replace(/__QUOTE__/g, '"');
            }
            if (Array.isArray(obj)) {
              return obj.map(restore);
            }
            if (typeof obj === 'object' && obj !== null) {
              return Object.fromEntries(
                Object.entries(obj).map(([k, v]) => [k, restore(v)])
              );
            }
            return obj;
          };
          return restore(parsed);
        } catch (innerError) {
          console.error('Error parsing complex JSON:', innerError);
          return val; // Return original value if all parsing attempts fail
        }
      }
    }
    return val;
  };

  const [val, setVal] = useState<any>(() => parseValue(value));

  useEffect(() => {
    const parsedValue = parseValue(value);
    if (JSON.stringify(val) !== JSON.stringify(parsedValue)) {
      setVal(parsedValue);
    }
  }, [value]);

  const handleChange = (newValue: any) => {
    setVal(newValue);
    onChange?.(JSON.stringify(newValue));
  };

  if (val === undefined) {
    return <div>NO data OR Not valid JSON</div>;
  }

  return (
    <Editor
      {...rest}
      value={val}
      onChange={handleChange}
      ace={ace}
      theme='ace/theme/solarized_light'
      mode={Editor.modes.code}
      allowedModes={[Editor.modes.tree, Editor.modes.code]}
    />
  );
};

export default JSONWidget;
