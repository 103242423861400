import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import tinymce from 'tinymce/tinymce';
import FileManager from './ImageRichText';
import { IS_DEBUG } from '@src/constants/constants';

// Default icons are required for TinyMCE 5.3 or above
import 'tinymce/icons/default';
// A theme is also required
import 'tinymce/themes/silver';

// Importing all plugins
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/help';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/spellchecker';
import 'tinymce/plugins/template';
import 'tinymce/plugins/textpattern';
import 'tinymce/plugins/noneditable';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';

(window as any).tinymce = tinymce;

interface RichTextProps {
  value?: string;
  onChange?: (val: string) => void;
  style?: Record<string, any>;
  mode?: 'simple' | 'advanced';
  height?: number;
  schema?: Record<string, any>;
  [x: string]: any;
}

const RichText: React.FC<RichTextProps> = ({
  value,
  onChange,
  style,
  height = 300,
  schema,
}) => {
  const [text, setText] = useState(value);
  const [fmVisible, setFmVisible] = useState(false);
  const [images, setImages] = useState<any[]>([]);
  const [, setIsVal] = useState(false);

  useEffect(() => {
    setText(value || '');
  }, [value]);

  const showFileManager = (cb: any) => {
    setFmVisible(true);
    window.tinycmeCallback = cb;
  };

  const hideFileManager = () => {
    setFmVisible(false);
  };

  const handleChange = (val: any) => {
    let arrImg: any = cloneDeep(images);
    if (schema?.richTextMode === 'advanced') {
      arrImg = concat(arrImg, val);
    } else {
      arrImg = concat([], (val || [])[0] || []);
    }
    setImages(arrImg);
    setIsVal(false);
    if (window.tinycmeCallback) {
      window.tinycmeCallback(`${val}`);
    }
  };

  const handleOk = () => {
    setFmVisible(false);
    setIsVal(true);
  };

  const onEditorChange = React.useCallback(
    (e: any) => {
      const val = e;
      setText(val);
      if (onChange) {
        onChange(val);
      }
    },
    [onChange]
  );

  const getEditorConfig = () => {
    const baseConfig = {
      height,
      menubar: true,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime table paste code help wordcount',
        'hr pagebreak spellchecker template textpattern noneditable emoticons',
      ],
      toolbar:
        'undo redo | formatselect | bold italic backcolor | ' +
        'alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | removeformat | help',
      content_style:
        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    };

    if (schema?.richTextMode === 'advanced') {
      return {
        ...baseConfig,
        plugins: [
          ...baseConfig.plugins,
          'textcolor',
          'contextmenu',
          'imagetools',
          'media',
        ],
        toolbar:
          'insertfile undo redo | styleselect | formatselect | fontselect | fontsizeselect | ' +
          'bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | ' +
          'bullist numlist outdent indent | link image media | forecolor backcolor emoticons | ' +
          'table | hr pagebreak | subscript superscript | charmap | ' +
          'searchreplace visualblocks code fullscreen preview | help',
        contextmenu: 'link image imagetools table spellchecker',
        extended_valid_elements: 'span[id|style|class]',
        image_advtab: true,
        image_caption: true,
        automatic_uploads: false,
        file_picker_types: 'image',
        file_picker_callback: function (callback: any, value: any, meta: any) {
          if (IS_DEBUG) {
            console.log('RichTextEditor file_picker_callback-> meta', meta);
            console.log('RichTextEditor file_picker_callback-> value', value);
          }
          showFileManager(callback);
        },
        media_live_embeds: false,
        media_url_resolver: (data: any, resolve: Function) => {
          resolve({ html: '' });
        },
        media_alt_source: false,
        media_poster: false,
        media_dimensions: false,
      };
    }

    return baseConfig;
  };

  return (
    <>
      {/* <Editor
        // tinymceScriptSrc="https://cdn.tiny.cloud/1/no-api-key/tinymce/5/tinymce.min.js"
        apiKey='cswezi492a2ax6zazo549bldqfazwrgrslsurvl1caolgntp'
        value={text}
        init={{
          language: 'vi',
          height: 300,
          plugins: [
            'code advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen textcolor',
            'insertdatetime media table contextmenu paste imagetools wordcount',
          ],
          toolbar:
            'insertfile undo redo | styleselect forecolor backcolor | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | code',
          extended_valid_elements: 'span[id|style|class]',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          // automatic_uploads: false,
          convert_urls: false,
          noneditable_noneditable_class: 'mceNonEditable',
          file_picker_types: 'image',
          file_picker_callback: function (callback, value, meta) {
            if (IS_DEBUG) {
              console.log('RichTextEditor file_picker_callback-> meta', meta);
              console.log('RichTextEditor file_picker_callback-> value', value);
            }
            showFileManager(callback);
          },
        }}
        onEditorChange={onEditorChange}
      /> */}
      <Editor
        apiKey='cswezi492a2ax6zazo549bldqfazwrgrslsurvl1caolgntp'
        value={text}
        init={{
          ...getEditorConfig(),
          language: 'vi',
          convert_urls: false,
          noneditable_noneditable_class: 'mceNonEditable',
        }}
        onEditorChange={onEditorChange}
      />
      <Modal
        zIndex={1310}
        style={{ top: '50px' }}
        width={500}
        title='Quản lý tệp'
        visible={fmVisible}
        onOk={handleOk}
        onCancel={hideFileManager}
        destroyOnClose={true}
      >
        <FileManager onChange={handleChange} />
      </Modal>
    </>
  );
};

export default RichText;
